import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import styles from './Benefits.module.scss';
import { BenefitsBlockContent, BenefitsBlockProps } from '../../../blocks/BenefitsBlock/BenefitsBlock';
import { Benefit } from '../../molecule/Benefit/Benefit';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { sortByPosition } from '../../../utils/sortByPosition';
import { Shape, ShapeColors } from '../../primitives/Shape/Shape';

export type BenefitsProps = Omit<BenefitsBlockProps, 'content'> & Omit<BenefitsBlockContent, 'id' | '__typename'>;

export const Benefits = ({
    className,
    benefits,
    benefitsDescription,
    benefitsTitle,
    highlightedText,
}: BenefitsProps): ReactElement => {
    if (!benefits) {
        return <></>;
    }

    const sortedBenefits = sortByPosition(benefits.data);

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={styles.shapeWrapper}>
                <Shape color={ShapeColors.orange} />
            </div>
            <div className="px-8 mb-10 tablet:px-0 tablet-landscape:flex tablet-landscape:justify-between tablet-landscape:mb-0">
                <div className={styles.titleWrapper}>
                    <div className="tablet-landscape:top-24 tablet-landscape:sticky">
                        <ColoredHeading
                            text={benefitsTitle}
                            highlightedText={highlightedText}
                            tag="h2"
                            className="mb-4"
                            size="md"
                        />
                        {benefitsDescription && <Markdown content={benefitsDescription} />}
                    </div>
                </div>
                <div className={styles.benefitsWrapper}>
                    {sortedBenefits.map((benefit) =>
                        benefit.attributes ? <Benefit key={benefit.id} {...benefit.attributes} /> : null,
                    )}
                </div>
            </div>
        </BlockWrapper>
    );
};
