import { Icon } from '../../primitives/Icon/Icon';
import { convertIconEnumToIconNameMap } from '../../../utils/convertIconEnumToIconNameMap';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import React from 'react';
import { CommonDetailHeroProps } from '../CommonDetailHero/CommonDetailHero';
import { CheckCardRow } from '../../molecule/CheckCardRow/CheckCardRow';
import clsx from 'clsx';
import { Badge } from '../../primitives/Badge/Badge';
import { trans } from '../../../utils/trans/trans';

export type CheckCardProps = Pick<CommonDetailHeroProps, 'title' | 'cardRow' | 'icon' | 'downloadReport' | 'state'>;

export const CheckCard = ({ cardRow, title, icon, downloadReport, state }: CheckCardProps) => {
    const isConsideration = state === 'consideration';
    const isFinding = state === 'finding';
    const resultIcon = isFinding ? 'cross' : isConsideration ? 'alert' : 'check';

    const text = isFinding
        ? trans('check_finding')
        : isConsideration
        ? trans('check_consideration')
        : trans('check_ok');

    const isFindingContent = (
        <>
            <div className={clsx('rounded-full mr-4 w-6 h-6 flex items-center justify-center bg-red')}>
                <Icon name={resultIcon} className="text-white" />
            </div>
            <Paragraph className={clsx('uppercase font-bold text-red')}>{text}</Paragraph>
        </>
    );

    const isNotFindingContent = (
        <Badge className={isConsideration ? 'bg-orange' : 'bg-primary'} withDefaultBackground={false} text={text} />
    );

    const resultContent = isFinding ? isFindingContent : isNotFindingContent;

    return (
        <div className="bg-white rounded-5xl px-10 py-8 shadow relative">
            <div className="text-green flex justify-between items-start mb-6 tablet:mb-10">
                {icon?.data?.attributes?.title && (
                    <Icon name={convertIconEnumToIconNameMap[icon.data.attributes.title]} size="lg" />
                )}
                <div className="text-green flex items-center">
                    <Paragraph className="font-bold mr-2 text-right">{downloadReport}</Paragraph>
                    <Icon name={'download'} />
                </div>
            </div>
            <Paragraph className="font-bold uppercase">{title}</Paragraph>
            <div className="mt-4 grid gap-1">
                {cardRow?.map((row) => row && <CheckCardRow key={row.id} {...row} />)}
            </div>
            <hr className="mt-6 mb-8 bg-lightGray tablet:mt-10" />
            <div className={clsx('flex items-center', !isFinding && 'justify-center')}>{resultContent}</div>
        </div>
    );
};
