/**
 * Import blocks which should be included in SSR
 */
import dynamic from 'next/dynamic';
import { BlockType } from '../headless';
import { PageProps } from '../types/page';
import { WebSettingsProps } from '../types/webSettings';
import { Providers } from '../types/providers';
import { Locale } from '../types/locale';
/**
 * Define fragment for blocks to load with app data
 */
import { graphql } from 'relay-runtime';
import BenefitsBlock from './BenefitsBlock/BenefitsBlock';
import BundleDetailBlock from './BundleDetailBlock/BundleDetailBlock';
import BundlesBlock from './BundlesBlock/BundlesBlock';
import ButtonBlock from './ButtonBlock/ButtonBlock';
import CheckDetailBlock from './CheckDetailBlock/CheckDetailBlock';
import ChecksBlock from './ChecksBlock/ChecksBlock';
import CompaniesBlock from './CompaniesBlock/CompaniesBlock';
import ContactBlock from './ContactBlock/ContactBlock';
import DocumentsBlock from './DocumentsBlock/DocumentsBlock';
import Error404Block from './Error404Block/Error404Block';
import FaQsBlock from './FAQsBlock/FaQsBlock';
import HeroBlock from './HeroBlock/HeroBlock';
import ImageBlock from './ImageBlock/ImageBlock';
import InfotextBlock from './InfotextBlock/InfotextBlock';
import MapBlock from './MapBlock/MapBlock';
import NewsDetailBlock from './NewsDetailBlock/NewsDetailBlock';
import NewsListBlock from './NewsListBlock/NewsListBlock';
import NumbersBlock from './NumbersBlock/NumbersBlock';
import PricingBlock from './PricingBlock/PricingBlock';
import PromoFloorBlock from './PromoFloorBlock/PromoFloorBlock';
import QuoteBlock from './QuoteBlock/QuoteBlock';
import ReferencesBlock from './ReferencesBlock/ReferencesBlock';
import RichtextBlock from './RichtextBlock/RichtextBlock';
import VideoBlock from './VideoBlock/VideoBlock';

graphql`
    fragment blocksContent on PageContentDynamicZone {
        __typename
        ...BenefitsBlock_content @relay(mask: false)
        ...BundleDetailBlock_content @relay(mask: false)
        ...BundlesBlock_content @relay(mask: false)
        ...ButtonBlock_content @relay(mask: false)
        ...ChecksBlock_content @relay(mask: false)
        ...CheckDetailBlock_content @relay(mask: false)
        ...CompaniesBlock_content @relay(mask: false)
        ...ContactBlock_content @relay(mask: false)
        ...DocumentsBlock_content @relay(mask: false)
        ...Error404Block_content @relay(mask: false)
        ...FaQsBlock_content @relay(mask: false)
        ...HeroBlock_content @relay(mask: false)
        ...ImageBlock_content @relay(mask: false)
        ...InfotextBlock_content @relay(mask: false)
        ...NewsDetailBlock_content @relay(mask: false)
        ...MapBlock_content @relay(mask: false)
        ...NewsListBlock_content @relay(mask: false)
        ...NumbersBlock_content @relay(mask: false)
        ...PricingBlock_content @relay(mask: false)
        ...PromoFloorBlock_content @relay(mask: false)
        ...QuoteBlock_content @relay(mask: false)
        ...ReferencesBlock_content @relay(mask: false)
        ...RichtextBlock_content @relay(mask: false)
        ...VideoBlock_content @relay(mask: false)
    }
`;
const blocks: { [name: string]: BlockType<PageProps, WebSettingsProps, Providers, Locale> } =
    process.env.NODE_ENV === 'production'
        ? {
              BenefitsBlock: dynamic(() => import('./BenefitsBlock/BenefitsBlock')),
              BundleDetailBlock: dynamic(() => import('./BundleDetailBlock/BundleDetailBlock')),
              BundlesBlock: dynamic(() => import('./BundlesBlock/BundlesBlock')),
              ButtonBlock: dynamic(() => import('./ButtonBlock/ButtonBlock')),
              CompaniesBlock: dynamic(() => import('./CompaniesBlock/CompaniesBlock')),
              ContactBlock: dynamic(() => import('./ContactBlock/ContactBlock')),
              CheckDetailBlock: dynamic(() => import('./CheckDetailBlock/CheckDetailBlock')),
              ChecksBlock: dynamic(() => import('./ChecksBlock/ChecksBlock')),
              DocumentsBlock: dynamic(() => import('./DocumentsBlock/DocumentsBlock')),
              Error404Block: dynamic(() => import('./Error404Block/Error404Block')),
              FaQsBlock: dynamic(() => import('./FAQsBlock/FaQsBlock')),
              HeroBlock: dynamic(() => import('./HeroBlock/HeroBlock')),
              ImageBlock: dynamic(() => import('./ImageBlock/ImageBlock')),
              InfotextBlock: dynamic(() => import('./InfotextBlock/InfotextBlock')),
              MapBlock: dynamic(() => import('./MapBlock/MapBlock')),
              NewsDetailBlock: dynamic(() => import('./NewsDetailBlock/NewsDetailBlock')),
              NewsListBlock: dynamic(() => import('./NewsListBlock/NewsListBlock')),
              NumbersBlock: dynamic(() => import('./NumbersBlock/NumbersBlock')),
              PricingBlock: dynamic(() => import('./PricingBlock/PricingBlock')),
              PromoFloorBlock: dynamic(() => import('./PromoFloorBlock/PromoFloorBlock')),
              QuoteBlock: dynamic(() => import('./QuoteBlock/QuoteBlock')),
              RichtextBlock: dynamic(() => import('./RichtextBlock/RichtextBlock')),
              ReferencesBlock: dynamic(() => import('./ReferencesBlock/ReferencesBlock')),
              VideoBlock: dynamic(() => import('./VideoBlock/VideoBlock')),
          }
        : {
              BenefitsBlock,
              BundleDetailBlock,
              BundlesBlock,
              ButtonBlock,
              CompaniesBlock,
              ContactBlock,
              CheckDetailBlock,
              ChecksBlock,
              DocumentsBlock,
              Error404Block,
              FaQsBlock,
              HeroBlock,
              ImageBlock,
              InfotextBlock,
              MapBlock,
              NewsDetailBlock,
              NewsListBlock,
              NumbersBlock,
              PricingBlock,
              PromoFloorBlock,
              QuoteBlock,
              ReferencesBlock,
              RichtextBlock,
              VideoBlock,
          };
export default blocks;
