import React from 'react';
import { Remark } from 'react-remark';
import { Paragraph, ParagraphProps } from '../Paragraph/Paragraph';
import { nbsp } from '../../../headless/utils';
import { Heading } from '../Heading/Heading';
import { List } from '../List/List';
import { Link } from '../Link/Link';
import clsx from 'clsx';

export type MarkdownProps = {
    content: string;
    paragraphClass?: string;
    paragraphDefaultStyles?: boolean;
};

export const Markdown = ({ content, paragraphClass, paragraphDefaultStyles }: MarkdownProps) => {
    const nbspContent = nbsp(content);

    if (!nbspContent) {
        return null;
    }

    return (
        <Remark
            rehypeReactOptions={{
                components: {
                    p: (props: ParagraphProps) => (
                        <Paragraph
                            {...props}
                            defaultStyles={paragraphDefaultStyles}
                            className={clsx(paragraphClass, 'w-full')}
                        />
                    ),
                    h1: (props: any) => <Heading {...props} tag="h1" className="w-full mb-4" />,
                    h2: (props: any) => <Heading {...props} tag="h2" className="w-full mb-4" />,
                    h3: (props: any) => <Heading {...props} tag="h3" className="w-full mb-4" />,
                    h4: (props: any) => <Heading {...props} tag="h4" className="w-full mb-4" />,
                    h5: (props: any) => <Heading {...props} tag="h5" className="w-full mb-4" />,
                    h6: (props: any) => <Heading {...props} tag="h6" className="w-full mb-4" />,
                    ul: (props: any) => <List {...props} tag="ul" />,
                    ol: (props: any) => <List {...props} tag="ol" />,
                    a: (props: any) => <Link {...props} target="_blank" className="underline hover:no-underline" />,
                },
            }}
        >
            {nbspContent}
        </Remark>
    );
};
