import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { PricingBlockContent, PricingBlockProps } from '../../../blocks/PricingBlock/PricingBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Pricing.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Pricing as PricingRow } from '../../molecule/Pricing/Pricing';

export type PricingProps = Omit<PricingBlockProps, 'content'> & Omit<PricingBlockContent, 'id' | '__typename'>;

export const Pricing = ({ className, pricingTitle, pricingDescription, pricings }: PricingProps): ReactElement => {
    if (!pricings?.data) {
        return <></>;
    }
    const sortedData = [...pricings.data].sort((p1, p2) => {
        const pricing1 = Number(p1.attributes?.price?.replace(/ /g, ''));
        const pricing2 = Number(p2.attributes?.price?.replace(/ /g, ''));

        if (isNaN(pricing1)) {
            return 1;
        }
        if (isNaN(pricing2)) {
            return -1;
        }

        if (pricing1 === pricing2) {
            return 0;
        } else if (pricing1 > pricing2) {
            return 1;
        } else {
            return -1;
        }
    });

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {pricingTitle && (
                <Heading tag="h2" className="text-center" size="md">
                    {pricingTitle}
                </Heading>
            )}
            {pricingDescription && (
                <div className="mt-4 text-center">
                    <Markdown content={pricingDescription} />
                </div>
            )}
            <div className={clsx((pricingTitle || pricingDescription) && 'mt-10 tablet:mt-20', 'w-full')}>
                {sortedData.map(
                    (pricing) => pricing.attributes && <PricingRow key={pricing.id} {...pricing.attributes} />,
                )}
            </div>
        </BlockWrapper>
    );
};
