/**
 * @generated SignedSource<<4da5433335ea6866c95e7e606cc69fe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RichtextBlock_content$data = {
  readonly id: string;
  readonly text: string;
  readonly " $fragmentType": "RichtextBlock_content";
};
export type RichtextBlock_content$key = {
  readonly " $data"?: RichtextBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"RichtextBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RichtextBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "text",
      "args": null,
      "kind": "ScalarField",
      "name": "Text",
      "storageKey": null
    }
  ],
  "type": "ComponentContentRichtext",
  "abstractKey": null
};

(node as any).hash = "45cddcabce75490b6d1305db5f538508";

export default node;
