import React, { ReactElement } from 'react';
import { Link } from '../../primitives/Link/Link';
import styles from './MainMenu.module.scss';
import { WebSettingsProps } from '../../../types/webSettings';
import { Icon } from '../../primitives/Icon/Icon';
import { useRouter } from 'next/router';
import clsx from 'clsx';

type MainMenuType = NonNullable<NonNullable<NonNullable<WebSettingsProps>['data']>['attributes']>['mainMenu'];

export interface MainMenuProps {
    menu: MainMenuType;
}

const MainMenu = ({ menu }: MainMenuProps): ReactElement => {
    const { asPath } = useRouter();
    const path = asPath.slice(1);
    return (
        <ul className={styles.menu1}>
            {menu?.data?.attributes?.menuItems?.data.map((link, i: number) => {
                let content = null;

                if (link.attributes?.page?.data) {
                    content = (
                        <Link
                            page={link.attributes.page.data}
                            className={clsx(
                                styles.link,
                                path === link.attributes.page.data.attributes?.url && 'text-green',
                            )}
                        >
                            {link.attributes.page.data.attributes?.title}
                            <Icon name="arrowRight" className={styles.arrow} />
                        </Link>
                    );
                }
                if (link.attributes?.route?.data) {
                    content = (
                        <Link route={link.attributes.route.data} className={styles.link}>
                            {link.attributes.route.data.attributes?.title}
                            <Icon name="arrowRight" className={styles.arrow} />
                        </Link>
                    );
                }

                return content ? (
                    <li key={`Mainmenu_${i}`} className="tablet-landscape:mr-10">
                        {content}
                    </li>
                ) : null;
            })}
        </ul>
    );
};

MainMenu.whyDidYouRender = true;

export { MainMenu };
