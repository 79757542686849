import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { NumbersBlockContent, NumbersBlockProps } from '../../../blocks/NumbersBlock/NumbersBlock';
import { Number } from '../../molecule/Number/Number';
import styles from './Numbers.module.scss';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';

export type NumbersProps = Omit<NumbersBlockProps, 'content'> & Omit<NumbersBlockContent, 'id' | '__typename'>;

export const Numbers = ({ className, numbers, numbersTitle, highlightedText }: NumbersProps): ReactElement => {
    if (!numbers?.data || numbers.data.length === 0) {
        return <></>;
    }

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {numbersTitle && (
                <ColoredHeading
                    text={numbersTitle}
                    highlightedText={highlightedText}
                    tag={'h2'}
                    className="mb-10 tablet:mb-20"
                    size="md"
                />
            )}
            <div className="grid gap-y-10 gap-x-8 w-full tablet:grid-cols-2 desktop:grid-cols-4">
                {numbers.data.map((number) => number.attributes && <Number key={number.id} {...number.attributes} />)}
            </div>
        </BlockWrapper>
    );
};
