import React from 'react';
import styles from './Footer.module.scss';
import { AppContextProps, getHrefFromRoute } from '../../../headless';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Link } from '../../primitives/Link/Link';
import { Icon } from '../../primitives/Icon/Icon';
import { Button } from '../../primitives/Button/Button';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { Badge } from '../../primitives/Badge/Badge';
import { Logo } from '../../primitives/Logo/Logo';
import { Newsletter } from '../../blocks/Newsletter/Newsletter';
import clsx from 'clsx';
import { Image } from '../../primitives/Image/Image';
import { useRouter } from 'next/router';
import { sortByPosition } from '../../../utils/sortByPosition';

export interface FooterProps {
    app: AppContextProps<PageProps, WebSettingsProps>;
}

export const Footer = ({ app }: FooterProps) => {
    const { webSetting } = app;
    const { asPath } = useRouter();

    if (!webSetting?.data?.attributes) {
        return null;
    }

    const { footerMenu, linkedin, facebook, homepage, login, startScauting } = webSetting.data.attributes;

    const sortedFooterMenu = sortByPosition(footerMenu?.data?.attributes?.menuItems?.data || []);

    return (
        <>
            <Newsletter app={app} key={asPath} />
            <footer>
                <div
                    className={clsx(
                        'py-10 px-12 tablet:py-16 tablet-landscape:py-20 desktop:flex desktop:justify-between desktop:gap-24 desktop:items-start',
                        styles.footer,
                    )}
                >
                    <div className="tablet:flex tablet:justify-between tablet:items-center desktop:flex-col desktop:gap-14 desktop:items-start">
                        <Link page={homepage?.data} className="block mb-10 tablet:mb-0">
                            <Logo />
                        </Link>
                        <div className="grid gap-2 tablet:grid-cols-2 desktop:grid-cols-1">
                            {startScauting?.data && (
                                <Button
                                    href={getHrefFromRoute(startScauting.data)}
                                    appearance={convertButtonAppearance(startScauting.data.attributes?.buttonType)}
                                    className="text-center"
                                    target={startScauting.data.attributes?.newTab ? '_blank' : '_self'}
                                >
                                    {startScauting.data.attributes?.title}
                                </Button>
                            )}
                            {login?.data && (
                                <Button
                                    href={getHrefFromRoute(login.data)}
                                    appearance={convertButtonAppearance(login.data.attributes?.buttonType)}
                                    className="text-center"
                                    target={login.data.attributes?.newTab ? '_blank' : '_self'}
                                >
                                    {login.data.attributes?.title}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="tablet-landscape:flex tablet-landscape:justify-between desktop:flex-grow desktop:justify-around">
                        {sortedFooterMenu.map((item) => (
                            <div key={item.id} className="mt-10 desktop:mt-0">
                                <Paragraph className="font-bold">{item.attributes?.title}</Paragraph>
                                <div className="mt-6 grid gap-6 tablet:grid-cols-2 tablet-landscape:grid-cols-1">
                                    {item.attributes?.menu?.data?.attributes?.menuItems?.data.map((menuItem) => {
                                        if (menuItem.attributes?.page?.data) {
                                            return (
                                                <Link key={menuItem.id} page={menuItem.attributes.page.data}>
                                                    {menuItem.attributes?.title}
                                                </Link>
                                            );
                                        }
                                        if (menuItem.attributes?.route?.data) {
                                            return (
                                                <Link key={menuItem.id} route={menuItem.attributes.route.data}>
                                                    {menuItem.attributes?.title}
                                                    {menuItem.attributes.route.data.attributes?.badge && (
                                                        <Badge
                                                            text={menuItem.attributes.route.data.attributes.badge}
                                                            className="ml-4"
                                                        />
                                                    )}
                                                </Link>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex gap-2 mt-10 desktop:flex-col desktop:mt-0">
                        <Link href={linkedin} target="_blank" className="inline-flex" title={'linkedin'}>
                            <Icon name="linkedin" className={styles.icons} />
                        </Link>
                        <Link href={facebook} target="_blank" className="inline-flex" title={'facebook'}>
                            <Icon name="facebook" className={styles.icons} />
                        </Link>
                    </div>
                </div>
                <div
                    className={clsx(
                        'py-10 px-12 border-t border-lightGray tablet-landscape:flex tablet-landscape:justify-between tablet-landscape:items-center',
                        styles.footer,
                    )}
                >
                    <div className="grid gap-4 justify-items-center items-center tablet:flex tablet:gap-10 tablet:justify-center">
                        <Image
                            src={
                                'https://cms.scaut.com/uploads/pbsa_67499ec24c.png?updated_at=2022-09-14T19:07:26.146Z'
                            }
                            width={70}
                            height={48}
                            alt={'certificate 1'}
                        />
                        <Image
                            src={
                                'https://cms.scaut.com/uploads/asis_0ce3bbdd1a.png?updated_at=2022-09-14T19:07:26.201Z'
                            }
                            width={96}
                            height={48}
                            alt={'certificate 2'}
                        />
                        <Image
                            src={
                                'https://cms.scaut.com/uploads/gdpr_058ee7faf1.png?updated_at=2022-09-14T19:07:26.168Z'
                            }
                            width={160}
                            height={30}
                            alt={'certificate 3'}
                        />
                        <Image
                            src={
                                'https://cms.scaut.com/uploads/napad_roku_a0e95b0e30.jpeg?updated_at=2022-12-10T07:42:41.156Z'
                            }
                            width={62}
                            height={48}
                            alt={'certificate 4'}
                        />
                    </div>
                    <div className="mt-8 tablet-landscape:mt-0">
                        <Paragraph className="text-center">&copy; {new Date().getFullYear()} SCAUT, s.r.o.</Paragraph>
                    </div>
                </div>
            </footer>
        </>
    );
};
