import React, { ButtonHTMLAttributes, useCallback } from 'react';
import clsx from 'clsx';
import { Link, LinkPageType } from '../Link/Link';
import { Icon, Icons } from '../Icon/Icon';
import styles from './Button.module.scss';
import { nbsp } from '../../../headless/utils';
import { trackButtonClick } from '../../../utils/gtm';

type IconPosition = 'left' | 'right';

export enum ButtonAppearance {
    filled = 'filled',
    outline = 'outline',
    ghost = 'ghost',
    outlineWhite = 'outlineWhite',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    href?: string | null;
    page?: LinkPageType | null;
    params?: Record<string, string | number>;
    icon?: Icons;
    iconPosition?: IconPosition;
    target?: string;
    appearance?: ButtonAppearance;
    filled?: boolean;
    contactRequired?: boolean;
}

const Button = ({
    children,
    href,
    page,
    params,
    icon,
    iconPosition = 'left',
    disabled,
    type,
    appearance = ButtonAppearance.filled,
    className,
    filled,
    contactRequired,
    ...rest
}: ButtonProps): JSX.Element => {
    const content = (
        <>
            {icon && iconPosition === 'left' && <Icon className={clsx(styles.icon, styles.iconOnLeft)} name={icon} />}
            {typeof children === 'string' ? nbsp(children) : children}
            {icon && iconPosition === 'right' && <Icon className={clsx(styles.icon, styles.iconOnRight)} name={icon} />}
        </>
    );

    const handleButtonClick = useCallback(() => {
        const text = typeof children === 'string' ? children : undefined;
        trackButtonClick(text);
    }, [children]);

    if ((href || page) && type !== 'submit') {
        return (
            <div
                className={clsx(
                    styles.button,
                    styles[appearance],
                    styles.hasLink,
                    disabled && styles.disabled,
                    filled && styles.filled,
                    className,
                )}
                onClick={handleButtonClick}
            >
                <Link
                    className={styles.link}
                    href={href}
                    page={page}
                    target={rest.target}
                    params={params}
                    contactRequired={contactRequired}
                >
                    {content}
                </Link>
            </div>
        );
    } else {
        return (
            <button
                className={clsx(
                    styles.button,
                    styles[appearance],
                    disabled && styles.disabled,
                    filled && styles.filled,
                    className,
                )}
                type={type}
                disabled={disabled}
                {...rest}
            >
                {content}
            </button>
        );
    }
};

Button.whyDidYouRender = true;

export { Button };
