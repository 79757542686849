import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { faqFragment$data } from '../../../relay/__generated__/faqFragment.graphql';
import { Icon } from '../../primitives/Icon/Icon';
import { Markdown } from '../../primitives/Markdown/Markdown';

export type FAQProps = NonNullable<faqFragment$data['attributes']>;

export const FAQ = ({ title, description }: FAQProps) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen((o) => !o);
    }, []);

    return (
        <div>
            <button type="button" onClick={toggleOpen} className={clsx('flex text-left', open && 'text-green')}>
                <Icon name={open ? 'minus' : 'plus'} className="text-green mr-6 mt-1" />
                {title}
            </button>
            <div
                className={clsx(
                    'pl-10 max-h-0 transition-all',
                    open && 'max-h-96 pt-4',
                    open ? 'overflow-auto' : 'overflow-hidden',
                )}
            >
                <Markdown content={description} />
            </div>
        </div>
    );
};
