import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { Error404Block_content$data } from './__generated__/Error404Block_content.graphql';
import { Error404 } from '../../components/blocks/Error404/Error404';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Error404BlockStaticProps {}

export interface Error404BlockContent extends OmitFragmentType<Error404Block_content$data> {
    __typename: 'ComponentContentError404';
}

export interface Error404BlockProps extends Error404BlockStaticProps {
    content: Error404BlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment Error404Block_content on ComponentContentError404 {
        id
        title: Title
        description: Description
    }
`;

const Error404Block = ({ content, ...otherProps }: Error404BlockProps): ReactElement => (
    <Error404 {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
Error404Block.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<Error404BlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

Error404Block.whyDidYouRender = true;

export default Error404Block;
