import { Link } from '../../primitives/Link/Link';
import { Image } from '../../primitives/Image/Image';
import { Heading } from '../../primitives/Heading/Heading';
import { ButtonIcon } from '../../primitives/ButtonIcon/ButtonIcon';
import { ButtonAppearance } from '../../primitives/Button/Button';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { promoItemFragment$data } from '../../../relay/__generated__/promoItemFragment.graphql';

type PromoCardProps = NonNullable<promoItemFragment$data['attributes']>;

export const PromoCard = ({ image, route, title, description }: PromoCardProps) => {
    return (
        <Link route={route?.data} className={'flex flex-col border border-lightGray rounded-3xl overflow-hidden'}>
            <div className={'relative h-50'}>
                {image?.data?.attributes && (
                    <Image image={image.data.attributes} className={'object-cover object-center'} fill />
                )}
            </div>
            <div className={'flex flex-col justify-between flex-grow gap-4 p-8'}>
                <div className={'grid gap-4'}>
                    <Heading tag={'h3'} size={'sm'} className={'uppercase line-clamp-2'}>
                        {title}
                    </Heading>
                    <Markdown content={description} />
                </div>
                <ButtonIcon icon={'arrowRight'} appearance={ButtonAppearance.outline} />
            </div>
        </Link>
    );
};
