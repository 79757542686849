/**
 * @generated SignedSource<<39bf716f58e952d3150c7ce400139dcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferencesBlock_content$data = {
  readonly id: string;
  readonly " $fragmentType": "ReferencesBlock_content";
};
export type ReferencesBlock_content$key = {
  readonly " $data"?: ReferencesBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferencesBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferencesBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ComponentContentReferences",
  "abstractKey": null
};

(node as any).hash = "68e735f7ff941fc02914e3d0505b550b";

export default node;
