import React, { ReactElement, ReactNode } from 'react';
import styles from './Paragraph.module.scss';
import clsx from 'clsx';
import { nbspChildren } from '../../../headless/utils';

export interface ParagraphProps {
    children?: ReactNode;
    className?: string;
    defaultStyles?: boolean;
}

const Paragraph = ({
    children,
    className,
    defaultStyles = true,
}: ParagraphProps): ReactElement<ParagraphProps, 'p'> | null => {
    if (!children) {
        return null;
    }

    return (
        <p className={clsx(defaultStyles && 'text-base mb-4', defaultStyles && styles.paragraph, className)}>
            {nbspChildren(children)}
        </p>
    );
};

Paragraph.whyDidYouRender = true;

export { Paragraph };
