import React, { ReactElement } from 'react';
import clsx from 'clsx';
import Slider, { Settings } from 'react-slick';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { CompaniesBlockContent, CompaniesBlockProps } from '../../../blocks/CompaniesBlock/CompaniesBlock';
import styles from './Companies.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Company } from '../../molecule/Company/Company';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';

export type CompaniesProps = Omit<CompaniesBlockProps, 'content'> & Omit<CompaniesBlockContent, 'id' | '__typename'>;

export const Companies = ({
    className,
    companies,
    companiesTitle,
    companiesDescription,
    grayscale,
    highlightedText,
}: CompaniesProps): ReactElement => {
    const settings: Settings = {
        dots: false,
        infinite: (companies?.data.length ?? 0) > 6,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                },
            },
        ],
    };

    if (!companies?.data) {
        return <></>;
    }

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {(companiesTitle || companiesDescription) && (
                <div className={clsx('px-12 text-center w-full', styles.content)}>
                    {companiesTitle && (
                        <ColoredHeading
                            text={companiesTitle}
                            highlightedText={highlightedText}
                            tag={'h2'}
                            size="md"
                            className="mb-4"
                        />
                    )}
                    {companiesDescription && <Markdown content={companiesDescription} />}
                </div>
            )}
            <div
                className={clsx(
                    'w-full',
                    (companiesTitle || companiesDescription) && 'mt-10',
                    grayscale && 'grayscale',
                )}
            >
                <Slider {...settings}>
                    {companies.data.map((company) => (
                        <Company key={company.id} company={company} />
                    ))}
                </Slider>
            </div>
        </BlockWrapper>
    );
};
