import dayjs from 'dayjs';
import timeZone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import styles from './NewsDetail.module.scss';
import { BlocksProps } from '../../base/Blocks/Blocks';
import { NewsDetailBlockProps } from '../../../blocks/NewsDetailBlock/NewsDetailBlock';
import { NewsDetailBlock_content$data } from '../../../blocks/NewsDetailBlock/__generated__/NewsDetailBlock_content.graphql';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { Link } from '../../primitives/Link/Link';
import { ArticleHero } from '../../molecule/ArticleHero/ArticleHero';
import { NewsListContent } from '../../organisms/NewsListContent/NewsListContent';
import { Author } from '../../molecule/Author/Author';
import { Icon } from '../../primitives/Icon/Icon';

const Blocks = dynamic<BlocksProps>(() => import('../../base/Blocks/Blocks').then((mod) => mod.Blocks));

export type NewsDetailProps = Omit<NewsDetailBlockProps, 'content' | 'relatedItems'> &
    Omit<NewsDetailBlock_content$data, 'id' | '__typename' | ' $fragmentType'>;

const NewsDetail = ({
    className,
    app,
    item: article,
    relatedArticles,
    moreArticles,
    backToList,
}: NewsDetailProps): ReactElement => {
    dayjs.extend(utc);
    dayjs.extend(timeZone);

    if (!article?.attributes || !app?.webSetting?.data?.attributes) {
        return <></>;
    }

    const { newsDetailPage } = app.webSetting.data.attributes;
    const { dateFrom, author, title, content, perex, image } = article.attributes;
    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={clsx('mb-10 w-full', styles.mediumContent)}>
                {backToList?.data && (
                    <Link route={backToList?.data} className="flex items-center text-green font-bold">
                        <Icon name="arrowLeft" className="mr-2" />
                        {backToList?.data.attributes?.title}
                    </Link>
                )}
            </div>
            <div className={styles.wideContent}>
                <ArticleHero dateFrom={dateFrom} image={image} perex={perex} title={title} />
            </div>
            <div className={styles.content}>
                <Blocks app={app} blocksData={content} />
            </div>
            <div className={styles.narrowContent}>
                <div className="border-t border-lightGray pt-10 pb-24 tablet:pt-14 tablet:pb-32">
                    {author?.data?.attributes && <Author author={author.data.attributes} />}
                </div>
                {relatedArticles && newsDetailPage && relatedArticles.length > 0 && (
                    <NewsListContent
                        moreArticles={moreArticles}
                        articlesList={relatedArticles}
                        newsDetailPage={newsDetailPage.data}
                    />
                )}
            </div>
        </BlockWrapper>
    );
};

NewsDetail.whyDidYouRender = true;

export { NewsDetail };
