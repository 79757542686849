/**
 * @generated SignedSource<<c62378488dec694bef83e574b1bed44f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactBlock_content$data = {
  readonly address: string | null;
  readonly bankAccount: string | null;
  readonly contactTitle: string | null;
  readonly dataBox: string;
  readonly dic: string | null;
  readonly email: string | null;
  readonly highlightedText: string | null;
  readonly iban: string | null;
  readonly id: string;
  readonly mailingAddress: string;
  readonly phone: string | null;
  readonly subtitle: string | null;
  readonly vatNumber: string | null;
  readonly " $fragmentType": "ContactBlock_content";
};
export type ContactBlock_content$key = {
  readonly " $data"?: ContactBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightedText",
      "storageKey": null
    },
    {
      "alias": "contactTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankAccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iban",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataBox",
      "storageKey": null
    }
  ],
  "type": "ComponentContentContact",
  "abstractKey": null
};

(node as any).hash = "2f8289ef8fcc20894583aa3cc60f013d";

export default node;
