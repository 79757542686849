import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { HeroBlockContent, HeroBlockProps } from '../../../blocks/HeroBlock/HeroBlock';
import styles from './Hero.module.scss';
import { Button } from '../../primitives/Button/Button';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { Image } from '../../primitives/Image/Image';
import { getHrefFromRoute } from '../../../headless';

export type HeroProps = Omit<HeroBlockProps, 'content'> & Omit<HeroBlockContent, 'id' | '__typename'>;

export const Hero = ({
    className,
    heroTitle,
    heroDescription,
    routes,
    highlightedText,
    heroImage,
    app,
}: HeroProps): ReactElement => {
    if (!app?.pages) {
        return <></>;
    }

    const showVisual = !!app.pages.data[0]?.attributes?.showVisual;
    const textClasses = clsx('text-center', showVisual && 'tablet-landscape:text-left');

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, showVisual && styles.blockWrapperVisual, className)}>
            <div
                className={clsx(
                    'w-full relative flex flex-col items-center',
                    showVisual && 'tablet-landscape:items-start',
                )}
            >
                <ColoredHeading text={heroTitle} highlightedText={highlightedText} className={textClasses} />
                {heroDescription && (
                    <div className="w-full mt-10">
                        <Markdown content={heroDescription} paragraphClass={textClasses} />
                    </div>
                )}
                {routes && routes.data.length > 0 && (
                    <div className="grid mt-10 gap-2 tablet:grid-cols-2">
                        {routes.data.map((route) => (
                            <Button
                                key={route.id}
                                href={getHrefFromRoute(route)}
                                appearance={convertButtonAppearance(route.attributes?.buttonType)}
                                target={route.attributes?.newTab ? '_blank' : '_self'}
                                filled
                            >
                                {route.attributes?.title}
                            </Button>
                        ))}
                    </div>
                )}
                {heroImage?.data?.attributes && (
                    <div className={clsx('mt-10 w-full tablet:mt-20', styles.image)}>
                        <Image image={heroImage.data.attributes} />
                    </div>
                )}
            </div>
        </BlockWrapper>
    );
};
