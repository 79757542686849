import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import clsx from 'clsx';
import axios from 'axios';
import { useRouter } from 'next/router';
import styles from './NewsList.module.scss';
import { NewsListBlockContent, NewsListBlockProps } from '../../../blocks/NewsListBlock/NewsListBlock';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ArticleHero } from '../../molecule/ArticleHero/ArticleHero';
import { Button } from '../../primitives/Button/Button';
import { Loader } from '../../primitives/Loader/Loader';
import { NewsListContent } from '../../organisms/NewsListContent/NewsListContent';

export type NewsListProps = Omit<NewsListBlockProps, 'content'> & Omit<NewsListBlockContent, 'id' | '__typename'>;

const NewsList = ({
    className,
    app,
    loadMore,
    articlesCount,
    moreArticles,
    articles,
}: NewsListProps): ReactElement<NewsListProps, 'div'> | null => {
    dayjs.extend(Calendar);
    const [loadedArticles, setLoadedArticles] = useState(articles);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(0);
    const { locale } = useRouter();
    const heroArticle = loadedArticles[0];
    const articlesList = loadedArticles.slice(1);

    const handleLoadMore = useCallback(() => setPage((p) => p + 1), []);
    const toggleLoading = useCallback(() => setLoading((l) => !l), []);

    useEffect(() => {
        if (page > 0) {
            const loadMore = async () => {
                try {
                    toggleLoading();
                    const res = await axios.post('/api/news/loadMore', {
                        page,
                        locale,
                    });

                    if (res.status === 200 && res.data && res.data.articles) {
                        setLoadedArticles((a) => [...a, ...res.data.articles]);
                    }

                    toggleLoading();
                } catch (e) {
                    console.log('error', e);
                }
            };

            loadMore();
        }
    }, [page, locale, toggleLoading]);

    const footer = useMemo(() => {
        if (loadedArticles.length === articlesCount) {
            return null;
        }

        return (
            <div className="mt-10 flex justify-center">
                {loading ? <Loader /> : <Button onClick={handleLoadMore}>{loadMore}</Button>}
            </div>
        );
    }, [articlesCount, loading, handleLoadMore, loadMore, loadedArticles]);

    if (!app?.webSetting?.data?.attributes) {
        return null;
    }

    const { newsDetailPage } = app.webSetting.data.attributes;

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {heroArticle.attributes && <ArticleHero {...heroArticle.attributes} detailPage={newsDetailPage?.data} />}
            <div className={clsx('mt-14 w-full', styles.content)}>
                <NewsListContent
                    articlesList={articlesList}
                    moreArticles={moreArticles}
                    newsDetailPage={newsDetailPage?.data}
                />

                {footer}
            </div>
        </BlockWrapper>
    );
};

NewsList.whyDidYouRender = true;

export { NewsList };
