import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { RichtextBlock_content$data } from './__generated__/RichtextBlock_content.graphql';
import { Richtext } from '../../components/blocks/Richtext/Richtext';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RichtextBlockStaticProps {}

export interface RichtextBlockContent extends OmitFragmentType<RichtextBlock_content$data> {
    __typename: 'ComponentContentRichtext';
}

export interface RichtextBlockProps extends RichtextBlockStaticProps {
    content: RichtextBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment RichtextBlock_content on ComponentContentRichtext {
        id
        text: Text
    }
`;

const RichtextBlock = ({ content, ...otherProps }: RichtextBlockProps): ReactElement => (
    <Richtext {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
RichtextBlock.getStaticProps = async ({
  locale,
  providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<RichtextBlockStaticProps> => {
  const provider = providers.x;

  return {};
};
*/
}

RichtextBlock.whyDidYouRender = true;

export default RichtextBlock;
