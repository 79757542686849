import React, { ReactElement } from 'react';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import styles from './CommonDetailHero.module.scss';
import { Link } from '../../primitives/Link/Link';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Button } from '../../primitives/Button/Button';
import { AppContextProps, getHrefFromRoute, OmitFragmentType } from '../../../headless';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { Image } from '../../primitives/Image/Image';
import { Icon } from '../../primitives/Icon/Icon';
import { checkDetailQuery$data } from '../../../relay/__generated__/checkDetailQuery.graphql';
import { bundleDetailQuery$data } from '../../../relay/__generated__/bundleDetailQuery.graphql';
import { appRouteFragment$data } from '../../../cms-strapi/relay/__generated__/appRouteFragment.graphql';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { BlocksProps } from '../../base/Blocks/Blocks';
import { ENUM_COMPONENTCONTENTCHECKDETAIL_STATE } from '../../../blocks/CheckDetailBlock/__generated__/CheckDetailBlock_content.graphql';
import { CheckDetailProps } from '../../blocks/CheckDetail/CheckDetail';
import { CheckCard } from '../CheckCard/CheckCard';
import { formatPrice } from '../../../utils/formatPrice';
import { trans } from '../../../utils/trans/trans';

const Blocks = dynamic<BlocksProps>(() => import('../../base/Blocks/Blocks').then((mod) => mod.Blocks));

export type CommonDetailHeroProps = NonNullable<NonNullable<checkDetailQuery$data['item']>['data']>['attributes'] &
    Omit<NonNullable<NonNullable<NonNullable<bundleDetailQuery$data['item']>['data']>['attributes']>, 'position'> & {
        back?: OmitFragmentType<appRouteFragment$data> | null;
        routes?: ReadonlyArray<OmitFragmentType<appRouteFragment$data>>;
        app: AppContextProps<PageProps, WebSettingsProps>;
        defaultCardRow: CheckDetailProps['cardRow'];
        downloadReport: string;
        defaultState: ENUM_COMPONENTCONTENTCHECKDETAIL_STATE;
    };

export const CommonDetailHero = ({
    back,
    routes,
    title,
    description,
    highlightedText,
    isOrange,
    image,
    currency,
    price,
    screenings,
    content,
    app,
    pricePer,
    state,
    cardRow,
    downloadReport,
    icon,
    defaultCardRow,
    defaultState,
}: CommonDetailHeroProps): ReactElement => {
    const { locale } = useRouter();
    const backgroundImage = isOrange
        ? 'https://cms.scaut.com/uploads/orange_shape_ca57186261.png?updated_at=2022-09-06T17:45:05.793Z'
        : 'https://cms.scaut.com/uploads/green_shape_1065c56adb.png?updated_at=2022-09-06T17:45:05.697Z';

    return (
        <>
            <BlockWrapper className={styles.blockWrapper}>
                <div className={styles.backgroundImage}>
                    <Image
                        src={backgroundImage}
                        alt={'background image'}
                        fill
                        className={'object-cover object-bottom'}
                    />
                </div>
                <div className={styles.content}>
                    {back && (
                        <Link route={back} className="text-green font-bold flex items-center mb-10">
                            <Icon name="arrowLeft" className="mr-2" />
                            {back.attributes?.title}
                        </Link>
                    )}
                    <ColoredHeading
                        text={title}
                        highlightedText={highlightedText}
                        isOrange={!!isOrange}
                        className={'break-words'}
                    />
                    <div className={styles.descriptionWrapper}>
                        <Markdown content={description} />
                    </div>
                    {screenings && (
                        <div className="mt-10">
                            <Paragraph className="font-bold uppercase text-xs mb-2" defaultStyles={false}>
                                {trans('screenings_in_packages')}
                            </Paragraph>
                            <Markdown content={screenings} paragraphClass={styles.screeningParagraph} />
                        </div>
                    )}
                    {currency && price && (
                        <div className="mt-10">
                            <Paragraph className="font-bold">
                                <span className="text-xl">{formatPrice(price, currency, locale)}</span>/{pricePer}
                            </Paragraph>
                        </div>
                    )}
                    {routes && (
                        <div className="mt-10 flex flex-col gap-2 tablet:flex-row">
                            {routes.map((route) => (
                                <Button
                                    key={route.id}
                                    href={getHrefFromRoute(route)}
                                    appearance={convertButtonAppearance(route.attributes?.buttonType)}
                                >
                                    {route.attributes?.title}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>

                <div className={clsx('mt-20 tablet-landscape:mt-0 w-full', styles.image)}>
                    {image?.data?.attributes && (
                        <div className="absolute -bottom-8 left-2 right-2 tablet:left-0 tablet:top-1/2 tablet:-translate-y-1/2 tablet:bottom-auto tablet-landscape:right-0 tablet-landscape:left-2 desktop:left-10">
                            <Image image={image.data.attributes} />
                        </div>
                    )}
                    <div className="tablet:w-2/3 tablet:mr-0 tablet:ml-auto tablet-landscape:ml-0 tablet-landscape:w-full large-desktop:w-96">
                        <CheckCard
                            state={state || defaultState}
                            cardRow={cardRow && cardRow.length > 0 ? cardRow : defaultCardRow}
                            downloadReport={downloadReport}
                            title={title}
                            icon={icon}
                        />
                    </div>
                </div>
            </BlockWrapper>
            {content && content.length > 0 && (
                <BlockWrapper className={styles.detailContent}>
                    <Blocks app={app} blocksData={content} />
                </BlockWrapper>
            )}
        </>
    );
};
