import React from 'react';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';
import styles from '../Button/Button.module.scss';
import customStyles from './ButtonIcon.module.scss';
import { ButtonAppearance, ButtonProps } from '../Button/Button';

export type ButtonIconProps = Omit<ButtonProps, 'children' | 'iconPosition' | 'icon' | 'href' | 'page' | 'params'> &
    Required<Pick<ButtonProps, 'icon'>>;

const ButtonIcon = ({
    icon,
    disabled,
    type,
    appearance = ButtonAppearance.filled,
    ...rest
}: ButtonIconProps): JSX.Element => {
    return (
        <button
            className={clsx(customStyles.button, styles[appearance], disabled && styles.disabled)}
            type={type}
            disabled={disabled}
            {...rest}
        >
            <Icon name={icon} />
        </button>
    );
};

ButtonIcon.whyDidYouRender = true;

export { ButtonIcon };
