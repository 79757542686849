import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { PricingBlock_content$data } from './__generated__/PricingBlock_content.graphql';
import { Pricing } from '../../components/blocks/Pricing/Pricing';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PricingBlockStaticProps {}

export interface PricingBlockContent extends OmitFragmentType<PricingBlock_content$data> {
    __typename: 'ComponentContentPricing';
}

export interface PricingBlockProps extends PricingBlockStaticProps {
    content: PricingBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment PricingBlock_content on ComponentContentPricing {
        id
        pricingTitle: title
        pricingDescription: description
        pricings {
            data {
                ...pricingFragment @relay(mask: false)
            }
        }
    }
`;

const PricingBlock = ({ content, ...otherProps }: PricingBlockProps): ReactElement => (
    <Pricing {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
PricingBlock.getStaticProps = async ({
  locale,
  providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<PricingBlockStaticProps> => {
  const provider = providers.x;

  return {};
};
*/
}

PricingBlock.whyDidYouRender = true;

export default PricingBlock;
