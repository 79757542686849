import { Locale } from '../../types/locale';

export const formatPrice = (price: string | null, currency: string | null, language?: string): string => {
    if (!language) {
        return '';
    }

    const languagesMap: { [t in Locale]: string } = {
        cs: `${price} ${currency ? currency : ''}`,
        en: `${currency ? currency : ''} ${price}`,
    };

    return languagesMap[language] || languagesMap['cs'];
};
