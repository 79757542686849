import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ImageBlockContent, ImageBlockProps } from '../../../blocks/ImageBlock/ImageBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Image.module.scss';
import { Image as ImageComponent } from '../../primitives/Image/Image';

export type ImageProps = Omit<ImageBlockProps, 'content'> & Omit<ImageBlockContent, 'id' | '__typename'>;

export const Image = ({ className, image, imageTitle }: ImageProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        {imageTitle && (
            <Heading tag={'h2'} className="mb-10 w-full">
                {imageTitle}
            </Heading>
        )}
        <div className="hidden w-full relative h-120 tablet-landscape:block">
            {image.data?.attributes && (
                <ImageComponent image={image.data.attributes} fill className={'object-center object-cover'} />
            )}
        </div>
        <div className="w-full relative tablet-landscape:hidden">
            {image.data?.attributes && <ImageComponent image={image.data.attributes} />}
        </div>
    </BlockWrapper>
);
