import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { BundlesBlock_content$data } from './__generated__/BundlesBlock_content.graphql';
import { Bundles } from '../../components/blocks/Bundles/Bundles';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BundlesBlockStaticProps {}

export interface BundlesBlockContent extends OmitFragmentType<BundlesBlock_content$data> {
    __typename: 'ComponentContentBundles';
}

export interface BundlesBlockProps extends BundlesBlockStaticProps {
    content: BundlesBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment BundlesBlock_content on ComponentContentBundles {
        id
        bundlesTitle: title
        bundlesDescription: description
        bundles {
            data {
                ...bundleFragment @relay(mask: false)
            }
        }
    }
`;

const BundlesBlock = ({ content, ...otherProps }: BundlesBlockProps): ReactElement => (
    <Bundles {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
BundlesBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<BundlesBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

BundlesBlock.whyDidYouRender = true;

export default BundlesBlock;
