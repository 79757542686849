import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { BundleDetailBlock_content$data } from './__generated__/BundleDetailBlock_content.graphql';
import { BundleDetail } from '../../components/blocks/BundleDetail/BundleDetail';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { getSlug } from '../../headless/utils';
import { bundleDetailQuery$data } from '../../relay/__generated__/bundleDetailQuery.graphql';

export interface BundleDetailBlockStaticProps {
    item?: NonNullable<bundleDetailQuery$data['item']>['data'];
}

export interface BundleDetailBlockContent extends OmitFragmentType<BundleDetailBlock_content$data> {
    __typename: 'ComponentContentBundleDetail';
}

export interface BundleDetailBlockProps extends BundleDetailBlockStaticProps {
    content: BundleDetailBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment BundleDetailBlock_content on ComponentContentBundleDetail {
        id
        backToBundles {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        routes {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        downloadReport
        bundleState: state
        cardRow {
            ...cardRowFragment @relay(mask: false)
        }
    }
`;

const BundleDetailBlock = ({ content, ...otherProps }: BundleDetailBlockProps): ReactElement => (
    <BundleDetail {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    BundleDetailBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview, params },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<BundleDetailBlockStaticProps> => {
        const provider = providers.bundle;

        if (!params?.slug) {
            return {};
        }

        const slug = getSlug(params.slug);
        if (!slug) {
            return {};
        }

        const { data } = await provider.find({
            locale,
            preview,
            offset: 0,
            limit: 1,
            filter: {
                slug: {
                    eq: slug,
                },
            },
        });

        if (data.length !== 1 || !data[0].id) {
            const err = new Error() as unknown as Error & { code: string };
            err.code = 'ENOENT';
            throw err;
        }

        const bundle = await provider.findOne({
            locale,
            preview,
            id: data[0].id,
        });

        return {
            item: bundle,
        };
    };
}

BundleDetailBlock.whyDidYouRender = true;

export default BundleDetailBlock;
