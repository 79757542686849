import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { QuoteBlockContent, QuoteBlockProps } from '../../../blocks/QuoteBlock/QuoteBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Quote.module.scss';
import { Icon } from '../../primitives/Icon/Icon';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Author } from '../../molecule/Author/Author';
import { Markdown } from '../../primitives/Markdown/Markdown';

export type QuoteProps = Omit<QuoteBlockProps, 'content'> & Omit<QuoteBlockContent, 'id' | '__typename'>;

export const Quote = ({ className, quote }: QuoteProps): ReactElement => {
    if (!quote?.data?.attributes) {
        return <></>;
    }

    const { content, author } = quote.data.attributes;

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className="w-full">
                <Icon name="quote" size="lg" className="mb-12" />
                <div className="mb-8">
                    <Markdown content={content} />
                </div>
                {author?.data?.attributes && <Author author={author.data.attributes} />}
            </div>
        </BlockWrapper>
    );
};
