import React, { FocusEvent, HTMLProps, InputHTMLAttributes, ReactElement, useCallback, useState } from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import styles from './PhoneInput.module.scss';
import PhoneInputDefault from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

export interface PhoneInputProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'ref' | 'value'> {
    error?: FieldError;
    onChange: (value: string) => void;
    value: string;
}

const PhoneInput = ({
    onChange,
    error,
    id,
    label,
    onBlur,
    ...props
}: PhoneInputProps): ReactElement<InputHTMLAttributes<HTMLInputElement>, 'input'> => {
    const [focused, setFocused] = useState(false);

    const handleFocused = useCallback(() => {
        setFocused(true);
    }, []);

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            onBlur?.(e);
            !e.target.value && setFocused(false);
        },
        [onBlur],
    );

    const isError = !!error;

    return (
        <div className={styles.container}>
            {label && (
                <label className={clsx(styles.label, focused && styles.labelFocused)} htmlFor={id}>
                    {label}
                </label>
            )}
            <PhoneInputDefault
                className={clsx(styles.input, focused && styles.inputFocused, isError && styles.errorTextarea)}
                defaultCountry="CZ"
                id={id}
                onFocus={handleFocused}
                onBlur={handleBlur}
                onChange={onChange}
                {...props}
            />
            {isError && <p className={styles.error}>{error.message}</p>}
        </div>
    );
};

PhoneInput.whyDidYouRender = true;

export { PhoneInput };
