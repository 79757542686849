import React, { ReactElement, useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { VideoBlockContent, VideoBlockProps } from '../../../blocks/VideoBlock/VideoBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Video.module.scss';
import scaut from '../../../../scaut.config.json';
import { Button } from '../../primitives/Button/Button';

export type VideoProps = Omit<VideoBlockProps, 'content'> & Omit<VideoBlockContent, 'id' | '__typename'>;

export const Video = ({ className, video, videoTitle }: VideoProps): ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const handleClick = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.play();
            setOverlayVisible(false);
        }
    }, [videoRef]);
    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {videoTitle && (
                <Heading tag={'h2'} className="mb-10 w-full">
                    {videoTitle}
                </Heading>
            )}
            <div className="pb-16/9 w-full relative">
                <video ref={videoRef} controls className="w-full h-full absolute top-0 left-0">
                    <source src={`${scaut.cms.domain}${video.data?.attributes?.url}`} type="video/mp4" />
                </video>
                {overlayVisible && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-25 backdrop-blur-xl flex items-center justify-center">
                        <Button onClick={handleClick}>Přehrát</Button>
                    </div>
                )}
            </div>
        </BlockWrapper>
    );
};
