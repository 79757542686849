import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ContactBlockContent, ContactBlockProps } from '../../../blocks/ContactBlock/ContactBlock';
import styles from './Contact.module.scss';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Icon } from '../../primitives/Icon/Icon';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Image } from '../../primitives/Image/Image';
import { Map } from '../../primitives/Map/Map';
import { trans } from '../../../utils/trans/trans';

export type ContactProps = Omit<ContactBlockProps, 'content'> & Omit<ContactBlockContent, 'id' | '__typename'>;

export const Contact = ({
    className,
    contactTitle,
    dic,
    vatNumber,
    address,
    email,
    bankAccount,
    iban,
    highlightedText,
    phone,
    subtitle,
    mailingAddress,
    dataBox,
}: ContactProps): ReactElement => {
    const blockClassname = 'tablet:flex tablet:items-center';
    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={styles.imageWrapper}>
                <Image
                    src="https://cms.scaut.com/uploads/green_shape_1065c56adb.png?updated_at=2022-09-12T12:00:12.017Z"
                    fill
                    alt={'background shape'}
                    className={'object-cover object-bottom'}
                />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.contentWrapper}>
                    {contactTitle && (
                        <div className={styles.headerWrapper}>
                            <ColoredHeading text={contactTitle} highlightedText={highlightedText} className="mb-10" />
                        </div>
                    )}
                    <div className={styles.mapWrapper}>
                        <Map />
                    </div>
                    <div className={styles.content}>
                        {subtitle && <Markdown content={subtitle} paragraphClass={styles.paragraph} />}
                        <div className="mt-10 grid gap-10 tablet:gap-6">
                            <div className={blockClassname}>
                                <Icon name="pin" size="md" />
                                <Paragraph className="mt-4 tablet:mt-0 tablet:ml-4">{address}</Paragraph>
                            </div>
                            <div className={blockClassname}>
                                <Icon name="pin" size="md" />
                                <div className="mt-4 tablet:mt-0 tablet:ml-4">
                                    <Paragraph defaultStyles={false}>{mailingAddress}</Paragraph>
                                </div>
                            </div>
                            <div className={blockClassname}>
                                <Icon name="phone" size="md" />
                                <a
                                    className="block mt-4 tablet:mt-0 tablet:ml-4"
                                    href={`tel:${phone?.replace(/ /g, '')}`}
                                >
                                    {phone}
                                </a>
                            </div>
                            <div className={blockClassname}>
                                <Icon name="email" size="md" />
                                <div className="mt-4 tablet:mt-0 tablet:ml-4">
                                    <a className="block" href={`mailto:${email}`}>
                                        {email}
                                    </a>
                                    <Paragraph defaultStyles={false}>{dataBox}</Paragraph>
                                </div>
                            </div>
                            <div className={blockClassname}>
                                <Icon name="bank" size="md" />
                                <div className="mt-4 tablet:mt-0 tablet:ml-4">
                                    <Paragraph defaultStyles={false}>
                                        {trans('bank_account')}: {bankAccount}
                                    </Paragraph>
                                    <Paragraph defaultStyles={false}>
                                        {trans('iban')}: {iban}
                                    </Paragraph>
                                </div>
                            </div>
                            <div className={blockClassname}>
                                <Icon name="company" size="md" />
                                <div className="mt-4 tablet:mt-0 tablet:ml-4">
                                    <Paragraph defaultStyles={false}>
                                        {trans('vat')}: {vatNumber}
                                    </Paragraph>
                                    <Paragraph defaultStyles={false}>
                                        {trans('dic')}: {dic}
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlockWrapper>
    );
};
