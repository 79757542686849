import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { VideoBlock_content$data } from './__generated__/VideoBlock_content.graphql';
import { Video } from '../../components/blocks/Video/Video';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VideoBlockStaticProps {}

export interface VideoBlockContent extends OmitFragmentType<VideoBlock_content$data> {
    __typename: 'ComponentContentVideo';
}

export interface VideoBlockProps extends VideoBlockStaticProps {
    content: VideoBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment VideoBlock_content on ComponentContentVideo {
        id
        videoTitle: Title
        video: Video {
            data {
                ...appVideoFragment @relay(mask: false)
            }
        }
    }
`;

const VideoBlock = ({ content, ...otherProps }: VideoBlockProps): ReactElement => (
    <Video {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
VideoBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<VideoBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

VideoBlock.whyDidYouRender = true;

export default VideoBlock;
