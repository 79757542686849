import React from 'react';
import dayjs from 'dayjs';
import styles from './ArticleHero.module.scss';
import { newsFragment$data } from '../../../relay/__generated__/newsFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { pageBaseFragment$data } from '../../../relay/__generated__/pageBaseFragment.graphql';
import { Heading } from '../../primitives/Heading/Heading';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Image } from '../../primitives/Image/Image';
import { Link } from '../../primitives/Link/Link';
import { DATE_FORMAT } from '../../../constants';

export type ArticleHeroProps = Omit<OmitFragmentType<newsFragment$data>, 'slug'> & {
    slug?: string;
} & {
    detailPage?: OmitFragmentType<pageBaseFragment$data> | null;
};

export const ArticleHero = ({ image, title, slug, perex, dateFrom, detailPage }: ArticleHeroProps) => {
    const content = (
        <>
            <div className={styles.content}>
                <Paragraph className={styles.date}>{dayjs(dateFrom).format(DATE_FORMAT)}</Paragraph>
                <Heading tag="h1" size="lg" className="mb-4">
                    {title}
                </Heading>
                {perex && <Markdown content={perex} paragraphClass="font-medium text-lg" />}
            </div>
            {image?.data?.attributes && (
                <div className="mt-10 h-120 relative tablet:mt-20">
                    <Image image={image.data.attributes} className={'object-cover object-center'} fill priority />
                </div>
            )}
        </>
    );

    if (!detailPage || !slug) {
        return <div className="pb-10 w-full">{content}</div>;
    }

    return (
        <Link page={detailPage} params={{ slug }} className="pb-10 block w-full">
            {content}
        </Link>
    );
};
