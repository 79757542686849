import { FormBase } from '../../molecule/FormBase/FormBase';
import { FormProvider, useForm } from 'react-hook-form';
import { AppStore } from '../../../headless';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { useCallback } from 'react';
import axios from 'axios';
import { trackFormSend } from '../../../utils/gtm';
import { FormData } from '../../blocks/Newsletter/Newsletter';
import styles from './CollectForm.module.scss';
import { useRouter } from 'next/router';

export type CollectFormProps = {
    onClose: () => void;
    url: string;
};

export const CollectForm = ({ onClose, url }: CollectFormProps) => {
    const form = useForm();
    const app = AppStore.getInstance<PageProps, WebSettingsProps>().get();
    const router = useRouter();

    const onSubmit = useCallback(
        async (data: FormData) => {
            const res = await axios.post('/api/form/send', {
                data: {
                    ...data,
                    message: url,
                },
            });

            if (res.status === 200) {
                trackFormSend();
                let finalUrl = url;
                if (!url.startsWith('http')) {
                    finalUrl = router.basePath + url;
                }
                window.open(finalUrl, '_blank');
                onClose();
            }

            return res;
        },
        [url],
    );

    if (!app.webSetting?.data?.attributes) {
        return <></>;
    }

    const { getContactFormButton, getContactFormDescription, getContactFormTitle, newsletterShape } =
        app.webSetting.data.attributes;

    return (
        <div className={styles.blockWrapper}>
            <FormProvider {...form}>
                <FormBase
                    title={getContactFormTitle}
                    description={getContactFormDescription}
                    buttonLabel={getContactFormButton}
                    onSubmit={onSubmit}
                    animate
                    shape={newsletterShape.data}
                    onClose={onClose}
                    passedStyles={{
                        block: styles.blockInner,
                        form: styles.form,
                        shape: styles.shape,
                        animatedShape: styles.animated,
                    }}
                />
            </FormProvider>
        </div>
    );
};
