import { BasePage } from '../../../cms';
import { getLinkParamsFromPage } from './getLinkParamsFromPage';
import AppStore from '../store/AppStore';
import { appRouteFragment$data } from '../../../cms-strapi/relay/__generated__/appRouteFragment.graphql';
import { OmitFragmentType } from '../../index';
import scaut from '../../../../scaut.config.json';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';

/**
 * Get href from Route object
 * @param route
 */
export function getHrefFromRoute(route: OmitFragmentType<appRouteFragment$data>): string {
    const app = AppStore.getInstance<PageProps, WebSettingsProps>().get();
    const attributes = route.attributes;

    if (!app.webSetting?.data?.attributes) {
        throw new Error('Web setting not defined');
    }

    if (attributes?.url) {
        // custom URL
        return attributes.url;
    }
    if (attributes?.file?.data?.attributes?.url) {
        // link to file
        return `${scaut.cms.domain}${attributes.file.data.attributes.url}`;
    }

    const params = attributes?.params
        ? JSON.parse(`{"${attributes.params.replace(/&/g, '","').replace(/=/g, '":"')}"}`, function (key, value) {
              return key === '' ? value : decodeURIComponent(value);
          })
        : {};

    if (attributes?.page?.data) {
        if (Object.prototype.hasOwnProperty.call(attributes.page.data.attributes, 'url')) {
            return getLinkParamsFromPage(attributes.page.data, params).as;
        }
    }

    if (attributes?.news?.data) {
        const pageKey = 'newsDetailPage';
        if (Object.prototype.hasOwnProperty.call(app.webSetting.data.attributes, pageKey)) {
            if (
                Object.prototype.hasOwnProperty.call(attributes.news.data.attributes, 'slug') &&
                (attributes.news.data.attributes as { slug?: string | null }).slug
            ) {
                return getLinkParamsFromPage(
                    ((app.webSetting.data.attributes as never)[pageKey] as any).data as NonNullable<BasePage>,
                    {
                        slug: (attributes.news.data.attributes as { slug: string }).slug,
                    },
                ).as;
            }
        }

        throw new Error('Link to ' + attributes.news.data.attributes?.slug + ' not defined');
    }

    if (attributes?.check?.data) {
        const pageKey = 'checkDetailPage';
        if (Object.prototype.hasOwnProperty.call(app.webSetting.data.attributes, pageKey)) {
            if (
                Object.prototype.hasOwnProperty.call(attributes.check.data.attributes, 'slug') &&
                (attributes.check.data.attributes as { slug?: string | null }).slug
            ) {
                return getLinkParamsFromPage(
                    ((app.webSetting.data.attributes as never)[pageKey] as any).data as NonNullable<BasePage>,
                    {
                        slug: (attributes.check.data.attributes as { slug: string }).slug,
                    },
                ).as;
            }
        }

        throw new Error('Link to ' + attributes.check.data.attributes?.slug + ' not defined');
    }

    if (attributes?.bundle?.data) {
        const pageKey = 'bundleDetailPage';
        if (Object.prototype.hasOwnProperty.call(app.webSetting.data.attributes, pageKey)) {
            if (
                Object.prototype.hasOwnProperty.call(attributes.bundle.data.attributes, 'slug') &&
                (attributes.bundle.data.attributes as { slug?: string | null }).slug
            ) {
                return getLinkParamsFromPage(
                    ((app.webSetting.data.attributes as never)[pageKey] as any).data as NonNullable<BasePage>,
                    {
                        slug: (attributes.bundle.data.attributes as { slug: string }).slug,
                    },
                ).as;
            }
        }

        throw new Error('Link to ' + attributes.bundle.data.attributes?.slug + ' not defined');
    }

    return '';
}
