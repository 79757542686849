import React, { ReactElement } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import scaut from '../../../../scaut.config.json';
import { appImageFragment$data } from '../../../cms-strapi/relay/__generated__/appImageFragment.graphql';

export declare type ImageProps = Omit<NextImageProps, 'src' | 'alt'> &
    (
        | {
              image: appImageFragment$data['attributes'];
              src?: never;
              alt?: never;
          }
        | {
              image?: never;
              src: NextImageProps['src'];
              alt: string;
          }
    );

export const Image = ({
    image,
    src,
    alt,
    title,
    width,
    height,
    onContextMenu = (e) => e.preventDefault(),
    ...props
}: ImageProps): ReactElement | null => {
    // 1) if no image is passed, use src and directly next/image
    if (!image?.url) {
        if (src) {
            const nextImageProps: NextImageProps = {
                ...props,
                src,
                alt,
                title,
                ...(typeof width === 'number' ? { width } : {}),
                ...(typeof height === 'number' ? { height } : {}),
            } as NextImageProps;
            return <NextImage onContextMenu={onContextMenu} {...nextImageProps} />;
        } else {
            return null;
        }
    }

    const widthResult = width || image.width;
    const heightResult = height || image.height;
    const composedUrl = `${scaut.cms.domain}${image.url}`;

    // 2) if width and height are passed use it to size image
    if (typeof widthResult === 'number' && typeof heightResult === 'number' && !props.fill) {
        return (
            <NextImage
                src={composedUrl}
                alt={alt || image.alternativeText || ''}
                title={title || image.name || undefined}
                width={widthResult}
                height={heightResult}
                onContextMenu={onContextMenu}
                {...(props as Omit<NextImageProps, 'src' | 'alt'> & { placeholder: 'blur'; blurDataURL: string })}
            />
        );
    }

    // 4) otherwise (no width & height) use layout fill
    return (
        <NextImage
            src={composedUrl}
            alt={alt || image.alternativeText || ''}
            title={title || image.name || undefined}
            onContextMenu={onContextMenu}
            placeholder={image.placeholder ? 'blur' : 'empty'}
            blurDataURL={image.placeholder ?? ''}
            fill
            {...(props as Omit<NextImageProps, 'src' | 'alt'> & {
                width: number;
                height: number;
            })}
        />
    );
};

Image.whyDidYouRender = true;
