import { ParsedUrlQuery } from 'querystring';
import { getLinkParamsFromUrl, LinkParams } from './getLinkParamsFromUrl';
import { getUrlFromPage } from './getUrlFromPage';
import { LinkPageType } from '../../../components/primitives/Link/Link';

export function getLinkParamsFromPage(
    page: LinkPageType,
    params: Record<string, string | number> | ParsedUrlQuery | undefined,
): LinkParams {
    return getLinkParamsFromUrl(getUrlFromPage(page, params));
}
