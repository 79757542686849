/**
 * @generated SignedSource<<fe47d104222d364721104f488485e0af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewsListBlock_content$data = {
  readonly id: string;
  readonly loadMore: string;
  readonly moreArticles: string;
  readonly " $fragmentType": "NewsListBlock_content";
};
export type NewsListBlock_content$key = {
  readonly " $data"?: NewsListBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewsListBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewsListBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "loadMore",
      "args": null,
      "kind": "ScalarField",
      "name": "Load_more",
      "storageKey": null
    },
    {
      "alias": "moreArticles",
      "args": null,
      "kind": "ScalarField",
      "name": "more_articles",
      "storageKey": null
    }
  ],
  "type": "ComponentContentNewsList",
  "abstractKey": null
};

(node as any).hash = "385573b90440309e8c51f03ca29d54c1";

export default node;
