import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { FAQsBlockContent, FAQsBlockProps } from '../../../blocks/FAQsBlock/FaQsBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './FAQs.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { FAQ } from '../../molecule/FAQ/FAQ';

export type FAQsProps = Omit<FAQsBlockProps, 'content'> & Omit<FAQsBlockContent, 'id' | '__typename'>;

export const FAQs = ({ className, faqs, faqsDescription, faqsTitle }: FAQsProps): ReactElement => {
    if (!faqs || faqs.data.length === 0) {
        return <></>;
    }

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            {faqsTitle && (
                <Heading tag={'h2'} className="mb-4 text-center" size="md">
                    {faqsTitle}
                </Heading>
            )}
            {faqsDescription && <Markdown content={faqsDescription} paragraphClass="text-center" />}

            <div className="mt-10 w-full grid gap-8 tablet:mt-20">
                {faqs.data.map((faq) => faq.attributes && <FAQ key={faq.id} {...faq.attributes} />)}
            </div>
        </BlockWrapper>
    );
};
