import { bundleFragment$data } from '../../../relay/__generated__/bundleFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { pageBaseFragment$data } from '../../../relay/__generated__/pageBaseFragment.graphql';
import { Link } from '../../primitives/Link/Link';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Button, ButtonAppearance } from '../../primitives/Button/Button';
import { Badge } from '../../primitives/Badge/Badge';
import { useRouter } from 'next/router';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import clsx from 'clsx';
import styles from './Bundle.module.scss';
import { Image } from '../../primitives/Image/Image';
import { formatPrice } from '../../../utils/formatPrice';
import { trans } from '../../../utils/trans/trans';

export type BundleProps = NonNullable<bundleFragment$data['attributes']> & {
    detailPage?: OmitFragmentType<pageBaseFragment$data> | null;
};

export const Bundle = ({
    currency,
    title,
    badgeText,
    price,
    slug,
    favorite,
    description,
    detailPage,
    screenings,
    pricePer,
}: BundleProps) => {
    const { locale } = useRouter();

    if (!detailPage) {
        return <></>;
    }

    const finalBadgeText = favorite ? badgeText || trans('favorite') : badgeText;

    const divider = (
        <hr className={clsx('h-px w-16 opacity-20 my-6 mx-auto border-0', favorite ? 'bg-white' : 'bg-black')} />
    );

    return (
        <Link
            page={detailPage}
            params={{ slug }}
            className={clsx(
                'flex flex-col justify-between relative rounded-4xl pt-10 px-4 py-6 h-full',
                favorite ? 'text-white bg-darkGreen' : 'bg-softLightGray',
            )}
        >
            {favorite && (
                <div className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-4xl">
                    <div className={styles.image}>
                        <Image
                            src="https://cms.scaut.com/uploads/green_shape_form_d0ac06abf4.png?updated_at=2022-09-06T17:45:05.957Z"
                            fill
                            alt={'background shape'}
                            className={'object-cover object-right-bottom'}
                        />
                    </div>
                </div>
            )}
            {finalBadgeText && (
                <div className="absolute top-0 -translate-y-1/2 left-0 w-full flex justify-center">
                    <Badge text={finalBadgeText} />
                </div>
            )}
            <div className="relative">
                <Paragraph className="mb-4 text-center text-xl font-bold" defaultStyles={false}>
                    {title}
                </Paragraph>
                <Markdown content={description} paragraphClass="text-center text-xs" paragraphDefaultStyles={false} />
            </div>
            <div className="relative">
                {divider}
                <div className="h-22 text-center text-xs">
                    <Paragraph className="font-bold mb-2" defaultStyles={false}>
                        {trans('screenings_in_packages')}
                    </Paragraph>
                    {screenings && (
                        <div className={styles.content}>
                            <Markdown content={screenings} />
                        </div>
                    )}
                </div>
                {divider}
                <Paragraph className="font-bold mb-6 text-center" defaultStyles={false}>
                    <span className="text-xl">{`${formatPrice(price, currency, locale)}`}</span>/{pricePer}
                </Paragraph>

                <Button type="button" appearance={ButtonAppearance.outline} className="w-full">
                    {trans('more')}
                </Button>
            </div>
        </Link>
    );
};
