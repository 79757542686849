import React, { FocusEvent, HTMLProps, ReactElement, TextareaHTMLAttributes, useCallback, useState } from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import styles from './Textarea.module.scss';
import { Icon, Icons } from '../Icon/Icon';

export interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
    readonly icon?: Icons;
    error?: FieldError;
}

const Textarea = ({
    onChange,
    icon,
    error,
    id,
    label,
    onBlur,
    ...props
}: TextareaProps): ReactElement<TextareaHTMLAttributes<HTMLTextAreaElement>, 'textarea'> => {
    const [focused, setFocused] = useState(false);

    const handleFocused = useCallback(() => {
        setFocused(true);
    }, []);

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLTextAreaElement>) => {
            onBlur?.(e);
            !e.target.value && setFocused(false);
        },
        [onBlur],
    );

    const isError = !!error;
    const ref = React.useRef<HTMLTextAreaElement>(null);

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
        if (e.target) {
            e.target.style.height = e.target.scrollHeight + 1 + 'px';
        }
        onChange && onChange(e);
    }

    return (
        <div className={styles.container}>
            {label && (
                <label className={clsx(styles.label, focused && styles.labelFocused)} htmlFor={id}>
                    {label}
                </label>
            )}
            {icon && <Icon name={icon} />}
            <textarea
                className={clsx(styles.textarea, focused && styles.inputFocused, isError && styles.errorTextarea)}
                ref={ref}
                onFocus={handleFocused}
                onBlur={handleBlur}
                {...props}
                id={id}
                onChange={handleChange}
                rows={5}
            />
            {isError && <p className={styles.error}>{error.message}</p>}
        </div>
    );
};

Textarea.whyDidYouRender = true;

export { Textarea };
