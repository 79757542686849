import clsx from 'clsx';
import styles from './Shape.module.scss';
import { ReactNode } from 'react';

export enum ShapeColors {
    orange = 'orange',
    red = 'red',
    green = 'green',
}

export type ShapeProps = {
    color: ShapeColors;
    className?: string;
    children?: ReactNode;
};

export const Shape = ({ color, className, children }: ShapeProps) => {
    return <div className={clsx(styles.shape, styles[color], className)}>{children}</div>;
};
