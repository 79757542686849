import React, { useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Icon } from '../../primitives/Icon/Icon';
import { Link } from '../../primitives/Link/Link';
import { NavbarProps } from '../../organisms/Navbar/Navbar';

export const LanguageSwitcher = ({ page, item }: Pick<NavbarProps, 'page' | 'item'>) => {
    const [languageSelectorOpen, setLanguageSelectorOpen] = useState<boolean>(false);
    const { locale, locales } = useRouter();

    return Array.isArray(locales) && locales.length > 1 ? (
        <div className="relative mr-4 tablet-landscape:ml-10 tablet-landscape:mr-0">
            <span
                className="uppercase flex items-center cursor-pointer"
                onClick={(): void => setLanguageSelectorOpen(!languageSelectorOpen)}
            >
                {locale}
                <Icon name="arrowDown" className={clsx('ml-4 transition', languageSelectorOpen && 'rotate-180')} />
            </span>
            {languageSelectorOpen && (
                <ul className="absolute shadow py-4 px-8 top-8 right-0 bg-white z-10">
                    {locales?.map((loc: string, i: number) => {
                        const localePage = page?.attributes?.localizations?.data?.find(
                            (l) => l?.attributes?.locale === loc,
                        );
                        const localeItem = item?.attributes?.localizations?.data?.find(
                            (l) => l?.attributes?.locale === loc,
                        );
                        return (
                            loc !== locale &&
                            localePage && (
                                <li key={`LanguageSelector_${i}`} className="uppercase mb-2 last:mb-0">
                                    <Link
                                        page={localePage}
                                        locale={loc}
                                        onClick={() => setLanguageSelectorOpen(false)}
                                        params={
                                            localeItem?.attributes?.slug ? { slug: localeItem?.attributes?.slug } : {}
                                        }
                                    >
                                        {loc}
                                    </Link>
                                </li>
                            )
                        );
                    })}
                </ul>
            )}
        </div>
    ) : (
        <span />
    );
};
