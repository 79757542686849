import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { PromoFloorBlock_content$data } from './__generated__/PromoFloorBlock_content.graphql';
import { PromoFloor } from '../../components/blocks/PromoFloor/PromoFloor';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PromoFloorBlockStaticProps {}

export interface PromoFloorBlockContent extends OmitFragmentType<PromoFloorBlock_content$data> {
    __typename: 'ComponentContentPromoFloor';
}

export interface PromoFloorBlockProps extends PromoFloorBlockStaticProps {
    content: PromoFloorBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment PromoFloorBlock_content on ComponentContentPromoFloor {
        id
        promoItems: promo_items {
            data {
                ...promoItemFragment @relay(mask: false)
            }
        }
    }
`;

const PromoFloorBlock = ({ content, ...otherProps }: PromoFloorBlockProps): ReactElement => (
    <PromoFloor {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
PromoFloorBlock.getStaticProps = async ({
  locale,
  providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<PromoBlockStaticProps> => {
  const provider = providers.x;

  return {};
};
*/
}

PromoFloorBlock.whyDidYouRender = true;

export default PromoFloorBlock;
