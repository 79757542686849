import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { MapBlock_content$data } from './__generated__/MapBlock_content.graphql';
import { Map } from '../../components/blocks/Map/Map';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MapBlockStaticProps {}

export interface MapBlockContent extends OmitFragmentType<MapBlock_content$data> {
    __typename: 'ComponentContentMap';
}

export interface MapBlockProps extends MapBlockStaticProps {
    content: MapBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment MapBlock_content on ComponentContentMap {
        id
        mapTitle: title
        mapDescription: description
        highlightedText
    }
`;

const MapBlock = ({ content, ...otherProps }: MapBlockProps): ReactElement => (
    <Map {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
MapBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<MapBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

MapBlock.whyDidYouRender = true;

export default MapBlock;
