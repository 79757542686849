import React, { ReactElement, useEffect, useState } from 'react';
import Hamburger from 'hamburger-react';
import { Link } from '../../primitives/Link/Link';
import { MainMenu } from '../MainMenu/MainMenu';
import { AppContextProps, getHrefFromRoute } from '../../../headless';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { Logo } from '../../primitives/Logo/Logo';
import { Button } from '../../primitives/Button/Button';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { useRouter } from 'next/router';
import { Icon } from '../../primitives/Icon/Icon';
import styles from './Navbar.module.scss';
import clsx from 'clsx';
import { pageDetailQuery$data } from '../../../relay/__generated__/pageDetailQuery.graphql';
import { LanguageSwitcher } from '../../molecule/LanguageSwitcher/LanguageSwitcher';
import { trans } from '../../../utils/trans/trans';

export interface NavbarProps {
    app: AppContextProps<PageProps, WebSettingsProps>;
    page?: NonNullable<pageDetailQuery$data['item']>['data'];
    item?: {
        attributes: {
            localizations?: {
                data?: {
                    attributes?: {
                        slug: string;
                        locale: string;
                    };
                }[];
            };
        };
    };
}

let position = 0;

const Navbar = ({ app, page, item }: NavbarProps): ReactElement<null, 'div'> | null => {
    const [isOpen, setOpen] = useState(false);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isTop, setIsTop] = useState(true);
    const { asPath } = useRouter();
    const { webSetting } = app;

    const handleScroll = () => {
        if (window.scrollY > position) {
            setIsNavbarVisible(false);
        }
        if (window.scrollY < position || window.scrollY === 0) {
            setIsNavbarVisible(true);
        }

        if (window.scrollY <= 10) {
            setIsTop(true);
        } else {
            setIsTop(false);
        }
        position = window.scrollY;
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setOpen(false);
    }, [asPath]);

    if (!webSetting?.data?.attributes) {
        return null;
    }

    const { homepage, mainMenu, startScauting, login, linkedin, facebook } = webSetting.data.attributes;

    const buttons = (
        <div className="flex flex-col-reverse w-full gap-2 tablet:flex-row tablet:w-auto">
            {login?.data && (
                <Button
                    href={getHrefFromRoute(login.data)}
                    appearance={convertButtonAppearance(login.data.attributes?.buttonType)}
                    className="text-center"
                    target={login.data.attributes?.newTab ? '_blank' : '_self'}
                >
                    {login.data.attributes?.title}
                </Button>
            )}
            {startScauting?.data && (
                <Button
                    href={getHrefFromRoute(startScauting.data)}
                    appearance={convertButtonAppearance(startScauting.data.attributes?.buttonType)}
                    className="text-center"
                    target={startScauting.data.attributes?.newTab ? '_blank' : '_self'}
                >
                    {startScauting.data.attributes?.title}
                </Button>
            )}
        </div>
    );

    return (
        <div
            className={clsx(
                'flex items-center flex-row justify-between left-0 right-0 top-0 py-4 px-8 z-30 transition-all',
                'tablet-landscape:translate-y-0',
                !isNavbarVisible ?? 'tablet-landscape:-translate-y-full',
                isOpen || !isTop ? 'bg-white' : '',
                isTop && !isOpen ? 'absolute' : 'fixed',
            )}
        >
            <Link page={homepage?.data} className="inline-flex">
                <Logo />
            </Link>
            <div
                className={clsx(
                    'fixed flex flex-col-reverse justify-end transition-transform left-0 top-20 bottom-0 z-10 pt-10 px-12 w-full bg-white items-center border-t border-lightGray',
                    isOpen ? 'translate-x-0' : 'translate-x-full',
                    'tablet:pt-0 tablet:top-22 tablet:items-end tablet:px-0 tablet:bg-lightGray tablet:bg-opacity-80 tablet:backdrop-blur-lg',
                    'tablet-landscape:items-center tablet-landscape:bg-white tablet-landscape:relative tablet-landscape:flex-row tablet-landscape:top-0 tablet-landscape:pt-0 tablet-landscape:border-t-0 tablet-landscape:translate-x-0 tablet-landscape:w-auto',
                )}
            >
                {mainMenu && (
                    <div className="w-full tablet:w-1/2 tablet:px-12 tablet:pb-12 tablet:bg-white tablet-landscape:w-auto tablet-landscape:p-0">
                        <MainMenu menu={mainMenu} />
                        <div className="mt-8 tablet-landscape:absolute tablet-landscape:invisible tablet-landscape:right-0 tablet-landscape:opacity-0">
                            <Paragraph className="font-bold uppercase">{trans('follow_us')}</Paragraph>
                            <div className="flex gap-2 mt-8">
                                <Link href={linkedin} target="_blank" className="inline-flex" title={'linkedin'}>
                                    <Icon name="linkedin" className={styles.icons} />
                                </Link>
                                <Link href={facebook} target="_blank" className="inline-flex" title={'facebook'}>
                                    <Icon name="facebook" className={styles.icons} />
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="w-full tablet:hidden tablet-landscape:block tablet-landscape:w-auto">{buttons}</div>
                <div className="hidden tablet-landscape:block">
                    <LanguageSwitcher page={page} item={item} />
                </div>
            </div>
            <div className="flex justify-between items-center tablet-landscape:hidden">
                <div className="hidden mr-10 tablet:block tablet-landscape:hidden">{buttons}</div>
                <LanguageSwitcher page={page} item={item} />
                <div className="-mr-4 tablet-landscape:hidden">
                    <Hamburger
                        direction="right"
                        toggled={isOpen}
                        toggle={setOpen}
                        distance="sm"
                        size={16}
                        color="#00CA72"
                        rounded
                        label={'hamburger menu button'}
                    />
                </div>
            </div>
        </div>
    );
};

Navbar.whyDidYouRender = true;

export { Navbar };
