import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { RichtextBlockContent, RichtextBlockProps } from '../../../blocks/RichtextBlock/RichtextBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Richtext.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';

export type RichtextProps = Omit<RichtextBlockProps, 'content'> & Omit<RichtextBlockContent, 'id' | '__typename'>;

export const Richtext = ({ className, text }: RichtextProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        <Markdown content={text} />
    </BlockWrapper>
);
