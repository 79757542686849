import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ReferencesBlockContent, ReferencesBlockProps } from '../../../blocks/ReferencesBlock/ReferencesBlock';
import styles from './References.module.scss';
import { Reference } from '../../molecule/Reference/Reference';
import Slider, { Settings } from 'react-slick';
import { Icon } from '../../primitives/Icon/Icon';

export type ReferencesProps = Omit<ReferencesBlockProps, 'content'> & Omit<ReferencesBlockContent, 'id' | '__typename'>;

export const References = ({ className, references }: ReferencesProps): ReactElement => {
    if (references.length === 0) {
        return <></>;
    }

    const settings: Settings = {
        dots: true,
        arrows: true,
        prevArrow: (
            <button type="button" className="slick-prev" title={'previous reference'}>
                <Icon name="arrowLeft" className="text-green" />
            </button>
        ),
        nextArrow: (
            <button type="button" className="slick-next" title={'next reference'}>
                <Icon name="arrowRight" className="text-green" />
            </button>
        ),
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={clsx(styles.content, 'w-full pb-14')}>
                <Slider {...settings}>
                    {references.map(
                        (reference) =>
                            reference.attributes && <Reference key={reference.id} {...reference.attributes} />,
                    )}
                </Slider>
            </div>
        </BlockWrapper>
    );
};
