import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { CheckDetailBlock_content$data } from './__generated__/CheckDetailBlock_content.graphql';
import { CheckDetail } from '../../components/blocks/CheckDetail/CheckDetail';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { getSlug } from '../../headless/utils';
import { checkDetailQuery$data } from '../../relay/__generated__/checkDetailQuery.graphql';

export interface CheckDetailBlockStaticProps {
    item?: NonNullable<checkDetailQuery$data['item']>['data'];
}

export interface CheckDetailBlockContent extends OmitFragmentType<CheckDetailBlock_content$data> {
    __typename: 'ComponentContentCheckDetail';
}

export interface CheckDetailBlockProps extends CheckDetailBlockStaticProps {
    content: CheckDetailBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment CheckDetailBlock_content on ComponentContentCheckDetail {
        id
        backToChecks: back {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        routes {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        downloadReport
        state
        cardRow {
            ...cardRowFragment @relay(mask: false)
        }
    }
`;

const CheckDetailBlock = ({ content, ...otherProps }: CheckDetailBlockProps): ReactElement => (
    <CheckDetail {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    CheckDetailBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview, params },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<CheckDetailBlockStaticProps> => {
        const provider = providers.check;

        if (!params?.slug) {
            return {};
        }

        const slug = getSlug(params.slug);
        if (!slug) {
            return {};
        }

        const { data } = await provider.find({
            locale,
            preview,
            offset: 0,
            limit: 1,
            filter: {
                slug: {
                    eq: slug,
                },
            },
        });

        if (data.length !== 1 || !data[0].id) {
            const err = new Error() as unknown as Error & { code: string };
            err.code = 'ENOENT';
            throw err;
        }

        const check = await provider.findOne({
            locale,
            preview,
            id: data[0].id,
        });

        return {
            item: check,
        };
    };
}

CheckDetailBlock.whyDidYouRender = true;

export default CheckDetailBlock;
