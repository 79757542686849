export type SortByPositionArray = Readonly<
    {
        attributes?: {
            position: number | null;
        } | null;
    }[]
>;

export const sortByPosition = <T extends SortByPositionArray>(array: T) =>
    [...array].sort((a, b) => {
        if (Number(a.attributes?.position) > Number(b.attributes?.position)) {
            return 1;
        } else if (Number(a.attributes?.position) < Number(b.attributes?.position)) {
            return -1;
        } else {
            return 0;
        }
    });
