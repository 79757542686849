import React from 'react';
import { Heading } from '../../primitives/Heading/Heading';
import { ArticleCard } from '../../molecule/ArticleCard/ArticleCard';
import { OmitFragmentType } from '../../../headless';
import { pageBaseFragment$data } from '../../../relay/__generated__/pageBaseFragment.graphql';
import { newsListQuery$data } from '../../../relay/__generated__/newsListQuery.graphql';

export type NewsListContentProps = {
    moreArticles: string;
    newsDetailPage?: OmitFragmentType<pageBaseFragment$data> | null;
    articlesList: NonNullable<newsListQuery$data['items']>['data'];
};

export const NewsListContent = ({ moreArticles, newsDetailPage, articlesList }: NewsListContentProps) => (
    <div>
        <Heading tag="h2" size="md">
            {moreArticles}
        </Heading>
        <div className="mt-10 grid gap-10">
            {articlesList.map(
                (article) =>
                    article.attributes && (
                        <ArticleCard key={article.id} {...article.attributes} detailPage={newsDetailPage} />
                    ),
            )}
        </div>
    </div>
);
