import React from 'react';
import { personFragment$data } from '../../../relay/__generated__/personFragment.graphql';
import { Image } from '../../primitives/Image/Image';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';

export type AuthorProps = { author: NonNullable<personFragment$data['attributes']> };

export const Author = ({ author: { image, title, occupation } }: AuthorProps) => (
    <div className="flex items-center">
        {image.data?.attributes && (
            <div className="mr-6 inline-flex rounded-full overflow-hidden">
                <Image image={image.data.attributes} width={56} height={56} sizes={'(min-width: 20rem) 56px'} />
            </div>
        )}
        <div>
            <Paragraph className="font-medium mb-0">{title}</Paragraph>
            {occupation && <Paragraph className="text-xs">{occupation}</Paragraph>}
        </div>
    </div>
);
