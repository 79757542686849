import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Error404BlockContent, Error404BlockProps } from '../../../blocks/Error404Block/Error404Block';
import { Hero } from '../Hero/Hero';

export type Error404Props = Omit<Error404BlockProps, 'content'> & Omit<Error404BlockContent, 'id' | '__typename'>;

const Error404 = ({ className, title, description }: Error404Props): ReactElement => (
    <div className={clsx('flex flex-col w-full h-full justify-center items-center', className)}>
        <Hero heroDescription={description} heroImage={null} heroTitle={title} highlightedText={null} routes={null} />
    </div>
);

Error404.whyDidYouRender = true;

export { Error404 };
