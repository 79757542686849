import React, { ReactElement, ReactNode } from 'react';

interface LayoutProps {
    children: ReactNode;
}

const LayoutComponent = ({ children }: LayoutProps): ReactElement | null => {
    return <div className={'mx-auto my-0 relative'}>{children}</div>;
};

export const Layout = LayoutComponent;
