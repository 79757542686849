import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { ReferencesBlock_content$data } from './__generated__/ReferencesBlock_content.graphql';
import { References } from '../../components/blocks/References/References';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { referenceListQuery$data } from '../../relay/__generated__/referenceListQuery.graphql';

export interface ReferencesBlockStaticProps {
    references: NonNullable<referenceListQuery$data['items']>['data'];
}

export interface ReferencesBlockContent extends OmitFragmentType<ReferencesBlock_content$data> {
    __typename: 'ComponentContentReferences';
}

export interface ReferencesBlockProps extends ReferencesBlockStaticProps {
    content: ReferencesBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment ReferencesBlock_content on ComponentContentReferences {
        id
    }
`;

const ReferencesBlock = ({ content, ...otherProps }: ReferencesBlockProps): ReactElement => (
    <References {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    ReferencesBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<ReferencesBlockStaticProps> => {
        const provider = providers.reference;

        const { data } = await provider.find({
            offset: 0,
            limit: 100,
            locale,
            preview,
        });

        return { references: data };
    };
}

ReferencesBlock.whyDidYouRender = true;

export default ReferencesBlock;
