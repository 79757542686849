import React from 'react';
import { numberFragment$data } from '../../../relay/__generated__/numberFragment.graphql';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';

export type NumberProps = NonNullable<numberFragment$data['attributes']>;

export const Number = ({ number, title }: NumberProps) => (
    <div className="pb-10 px-10 border-b border-lightGray flex flex-col items-center">
        <Paragraph className="mb-4 font-bold text-4xl" defaultStyles={false}>
            {number}
        </Paragraph>
        <Paragraph>{title}</Paragraph>
    </div>
);
