import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { HeroBlock_content$data } from './__generated__/HeroBlock_content.graphql';
import { Hero } from '../../components/blocks/Hero/Hero';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeroBlockStaticProps {}

export interface HeroBlockContent extends OmitFragmentType<HeroBlock_content$data> {
    __typename: 'ComponentContentHero';
}

export interface HeroBlockProps extends HeroBlockStaticProps {
    content: HeroBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment HeroBlock_content on ComponentContentHero {
        id
        heroTitle: Title
        heroDescription: Description
        highlightedText: Highlighted_text
        routes: Routes {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        heroImage: image {
            data {
                ...appImageFragment @relay(mask: false)
            }
        }
    }
`;

const HeroBlock = ({ content, ...otherProps }: HeroBlockProps): ReactElement => (
    <Hero {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
HeroBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<HeroBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

HeroBlock.whyDidYouRender = true;

export default HeroBlock;
