/**
 * @generated SignedSource<<ac66d9fb184a7171e21e73350b48119e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompaniesBlock_content$data = {
  readonly companiesDescription: string | null;
  readonly companiesTitle: string | null;
  readonly grayscale: boolean | null;
  readonly highlightedText: string | null;
  readonly id: string;
  readonly " $fragmentType": "CompaniesBlock_content";
};
export type CompaniesBlock_content$key = {
  readonly " $data"?: CompaniesBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompaniesBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompaniesBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "companiesTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightedText",
      "storageKey": null
    },
    {
      "alias": "companiesDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grayscale",
      "storageKey": null
    }
  ],
  "type": "ComponentContentCompanies",
  "abstractKey": null
};

(node as any).hash = "8bb7c29bf1cd4381c63973e8f9ec85d0";

export default node;
