import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { BundlesBlockContent, BundlesBlockProps } from '../../../blocks/BundlesBlock/BundlesBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Bundles.module.scss';
import { Bundle } from '../../molecule/Bundle/Bundle';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { ButtonIcon } from '../../primitives/ButtonIcon/ButtonIcon';
import { sortByPosition } from '../../../utils/sortByPosition';

export type BundlesProps = Omit<BundlesBlockProps, 'content'> & Omit<BundlesBlockContent, 'id' | '__typename'>;

export const Bundles = ({ className, app, bundlesTitle, bundles, bundlesDescription }: BundlesProps): ReactElement => {
    const scrollbarRef = useRef<HTMLDivElement>(null);
    const [arrowLeftVisible, setArrowLeftVisible] = useState(false);
    const [arrowRightVisible, setArrowRightVisible] = useState(true);

    const handleScroll = useCallback(() => {
        if (scrollbarRef.current) {
            if (scrollbarRef.current.scrollLeft > 0) {
                setArrowLeftVisible(true);
            } else if (scrollbarRef.current.scrollLeft === 0) {
                setArrowLeftVisible(false);
            }

            if (
                scrollbarRef.current.scrollLeft ===
                scrollbarRef.current.scrollWidth - scrollbarRef.current.clientWidth
            ) {
                setArrowRightVisible(false);
            } else {
                setArrowRightVisible(true);
            }
        }
    }, [scrollbarRef]);

    useEffect(() => {
        if (scrollbarRef.current) {
            scrollbarRef.current.addEventListener('scroll', handleScroll);

            return () => scrollbarRef.current?.removeEventListener('scroll', handleScroll);
        }
    }, [scrollbarRef, handleScroll]);

    const handleRightButtonClick = useCallback(() => {
        scrollbarRef.current?.scrollTo({
            top: 0,
            left: window.innerWidth,
            behavior: 'smooth',
        });
    }, [scrollbarRef]);

    const handleLeftButtonClick = useCallback(() => {
        scrollbarRef.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [scrollbarRef]);

    if (!bundles || bundles.data.length === 0 || !app?.webSetting?.data?.attributes) {
        return <></>;
    }

    const { bundleDetailPage } = app.webSetting.data.attributes;

    const sortedData = sortByPosition(bundles.data);

    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={styles.content}>
                <Heading tag="h2" size="md">
                    {bundlesTitle}
                </Heading>
                {bundlesDescription && (
                    <div className="mt-4">
                        <Markdown content={bundlesDescription} />
                    </div>
                )}
            </div>
            <div className="w-full relative">
                {arrowLeftVisible && (
                    <div className="hidden absolute z-10 top-1/2 -translate-y-1/2 left-4 tablet-landscape:block bundles-arrows-hide:hidden">
                        <ButtonIcon icon="arrowLeft" onClick={handleLeftButtonClick} />
                    </div>
                )}
                <div
                    className={clsx(
                        styles.scrollWrapper,
                        'pt-14 w-full flex overflow-y-hidden px-4 scrollbar-hide relative',
                    )}
                    ref={scrollbarRef}
                >
                    {sortedData.map(
                        (bundle) =>
                            bundle.attributes && (
                                <div
                                    key={bundle.id}
                                    className="mr-4 flex-shrink-0 w-72 last:mr-0 tablet:w-72 tablet-landscape:w-64 large-desktop:w-auto large-desktop:flex-shrink large-desktop:flex-1"
                                >
                                    <Bundle {...bundle.attributes} detailPage={bundleDetailPage?.data} />
                                </div>
                            ),
                    )}
                </div>
                {arrowRightVisible && (
                    <div className="hidden absolute z-10 top-1/2 -translate-y-1/2 right-4 tablet-landscape:block bundles-arrows-hide:hidden">
                        <ButtonIcon icon="arrowRight" onClick={handleRightButtonClick} />
                    </div>
                )}
            </div>
        </BlockWrapper>
    );
};
