import { ButtonAppearance } from '../../components/primitives/Button/Button';
import { ENUM_ROUTE_BUTTON_TYPE } from '../../blocks/HeroBlock/__generated__/HeroBlock_content.graphql';

export default function (appearance?: ENUM_ROUTE_BUTTON_TYPE | null): ButtonAppearance {
    switch (appearance) {
        case 'Filled':
            return ButtonAppearance.filled;
        case 'Outline':
            return ButtonAppearance.outline;
        default:
            return ButtonAppearance.filled;
    }
}
