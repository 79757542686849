/**
 * @generated SignedSource<<111e55a6e162d4ce92d8b2533e6389a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ENUM_ICON_TITLE = "Advers_media_V01_Green" | "Advers_media_V01_Orange" | "Advers_media_V02_Green" | "Advers_media_V02_Orange" | "Business_Green" | "Business_Orange" | "Certification_Green" | "Certification_Orange" | "Company_Green" | "Company_Orange" | "Conflict_Green" | "Conflict_Orange" | "Credibility_Green" | "Credibility_Orange" | "Debtlessness_Green" | "Debtlessness_Orange" | "Document_Green" | "Document_Orange" | "Education_Green" | "Education_Orange" | "Effective_process_Green" | "Effective_process_Orange" | "Effective_process_V02_Green" | "Effective_process_V02_Orange" | "Employees_Green" | "Employees_Orange" | "GDPR_Green" | "GDPR_Orange" | "History_Green" | "History_Orange" | "Impunity_and_integrity_Green" | "Impunity_and_integrity_Orange" | "Integrity" | "Media_monitoring_Green" | "Media_monitoring_Orange" | "Personal_documents_Green" | "Personal_documents_Orange" | "Police_badge_Green" | "Police_badge_Orange" | "Rating_Green" | "Rating_Orange" | "Refere_people_Green" | "Refere_people_Orange" | "Save_Green" | "Save_Orange" | "Save_money_and_time_Green" | "Save_money_and_time_Orange" | "Shield_Green" | "Shield_Orange" | "Speed_Green" | "Speed_Orange" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BenefitsBlock_content$data = {
  readonly benefits: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly description: string | null;
        readonly icon: {
          readonly data: {
            readonly attributes: {
              readonly title: ENUM_ICON_TITLE | null;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly position: number;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly benefitsDescription: string;
  readonly benefitsTitle: string;
  readonly highlightedText: string | null;
  readonly id: string;
  readonly " $fragmentType": "BenefitsBlock_content";
};
export type BenefitsBlock_content$key = {
  readonly " $data"?: BenefitsBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"BenefitsBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BenefitsBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "benefitsTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "Title",
      "storageKey": null
    },
    {
      "alias": "benefitsDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "Description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightedText",
      "storageKey": null
    },
    {
      "alias": "benefits",
      "args": null,
      "concreteType": "BenefitRelationResponseCollection",
      "kind": "LinkedField",
      "name": "Benefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BenefitEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Benefit",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": "title",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Title",
                  "storageKey": null
                },
                {
                  "alias": "description",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IconEntityResponse",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IconEntity",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Icon",
                          "kind": "LinkedField",
                          "name": "attributes",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentBenefits",
  "abstractKey": null
};
})();

(node as any).hash = "60878c6f70814ac2c9a134ddbcb47afa";

export default node;
