import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Lottie from 'lottie-react';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { InfotextBlockContent, InfotextBlockProps } from '../../../blocks/InfotextBlock/InfotextBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Infotext.module.scss';
import { Image } from '../../primitives/Image/Image';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Button } from '../../primitives/Button/Button';
import { getHrefFromRoute } from '../../../headless';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { useIsInViewport } from '../../../hooks/useIsInViewport';
import { Shape, ShapeColors } from '../../primitives/Shape/Shape';
import macbookMocup from '../../../../public/images/macbook-mockup.png';
import scaut from '../../../../scaut.config.json';

export type InfotextProps = Omit<InfotextBlockProps, 'content'> & Omit<InfotextBlockContent, 'id' | '__typename'>;

export const Infotext = ({
    className,
    infotextDescription,
    infotextTitle,
    route,
    wideImage,
    backgroundImagePosition,
    animationFile,
    infotextVideo,
}: InfotextProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const [animation, setAnimation] = useState();
    const [animate, setAnimate] = useState(false);
    const isInViewport = useIsInViewport(ref);

    useEffect(() => {
        const downloadData = async () => {
            const animationFileUrl = animationFile?.data?.attributes?.url;
            if (animationFileUrl) {
                const url = `${scaut.cms.domain}${animationFileUrl}`;

                const result = await fetch(url);
                const data = await result.json();

                setAnimation(data);
            }
        };

        downloadData();
    }, [animationFile?.data?.attributes?.url]);

    const isTop = backgroundImagePosition === 'Top';
    const shapeColor = isTop ? ShapeColors.green : ShapeColors.orange;

    const maybeRenderShape = useMemo(() => {
        if (!backgroundImagePosition) {
            return null;
        }
        if (backgroundImagePosition === 'Top') {
            return (
                <div className={styles.backgroundImageTopWrapper}>
                    <div className={clsx(styles.backgroundImage, styles.positionTop, animate && styles.animating)}>
                        <Shape color={shapeColor} />
                    </div>
                </div>
            );
        }

        if (backgroundImagePosition === 'Bottom') {
            return (
                <div className={styles.backgroundImageBottomWrapper}>
                    <div className={clsx(styles.backgroundImage, styles.positionBottom, animate && styles.animating)}>
                        <Shape color={shapeColor} />
                    </div>
                </div>
            );
        }
    }, [backgroundImagePosition, animate]);

    useEffect(() => {
        if (isInViewport && !animate) {
            setAnimate(true);
        }
    }, [isInViewport, animate]);

    const maybeRenderVideo = infotextVideo?.data && (
        <video className="w-full" autoPlay muted loop playsInline controls>
            <source src={`${scaut.cms.domain}${infotextVideo.data.attributes?.url}`} type="video/mp4" />
        </video>
    );
    const maybeRenderAnimation = !infotextVideo?.data && (
        <>
            <Image
                src={macbookMocup}
                alt={'macbook mocup'}
                sizes={'(max-width: 64rem) 100%, (max-width: 90rem) 47vw, (max-width: 120rem) 40vw, 35vw'}
                className={clsx(!animation && 'opacity-0')}
            />
            {animation && <Lottie animationData={animation} loop className={styles.animationWrapper} />}
        </>
    );

    return (
        <BlockWrapper
            ref={ref}
            className={clsx(styles.blockWrapper, !wideImage && styles.row, wideImage && styles.wide, className)}
        >
            {maybeRenderShape}
            <div
                className={clsx(
                    'mb-10 relative tablet-landscape:mb-0',
                    !wideImage && 'tablet-landscape:mb-0',
                    styles.image,
                    wideImage && styles.wideImageWrapper,
                )}
            >
                {maybeRenderAnimation}
                {maybeRenderVideo}
            </div>
            <div className={clsx(styles.content, wideImage ? styles.wideContent : undefined)}>
                <div>
                    <Heading tag="h2" size="sm" className="uppercase mb-4">
                        {infotextTitle}
                    </Heading>
                    {infotextDescription && <Markdown content={infotextDescription} />}
                </div>
                {route?.data && (
                    <div className={clsx('mt-10 tablet-landscape:flex-shrink-0', wideImage && 'tablet-landscape:mt-0')}>
                        <Button
                            href={getHrefFromRoute(route.data)}
                            target={route.data.attributes?.newTab ? '_blank' : '_self'}
                            appearance={convertButtonAppearance(route.data.attributes?.buttonType)}
                        >
                            {route.data.attributes?.title}
                        </Button>
                    </div>
                )}
            </div>
        </BlockWrapper>
    );
};
