import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { ChecksBlock_content$data } from './__generated__/ChecksBlock_content.graphql';
import { Checks } from '../../components/blocks/Checks/Checks';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { checkListQuery$data } from '../../relay/__generated__/checkListQuery.graphql';

export interface ChecksBlockStaticProps {
    checks: NonNullable<checkListQuery$data['items']>['data'];
}

export interface ChecksBlockContent extends OmitFragmentType<ChecksBlock_content$data> {
    __typename: 'ComponentContentChecks';
}

export interface ChecksBlockProps extends ChecksBlockStaticProps {
    content: ChecksBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment ChecksBlock_content on ComponentContentChecks {
        id
        checksTitle: title
        checksDescription: description
    }
`;

const ChecksBlock = ({ content, ...otherProps }: ChecksBlockProps): ReactElement => (
    <Checks {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    ChecksBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<ChecksBlockStaticProps> => {
        const provider = providers.check;

        const { data } = await provider.find({
            locale,
            limit: 100,
            offset: 0,
            preview,
        });

        return {
            checks: data,
        };
    };
}

ChecksBlock.whyDidYouRender = true;

export default ChecksBlock;
