import { Heading, HeadingProps } from '../Heading/Heading';
import React from 'react';
import styles from './ColoredHeading.module.scss';
import { nbsp } from '../../../headless/utils';
import clsx from 'clsx';

export type ColoredHeadingProps = {
    text: string;
    highlightedText: string | null;
    tag?: HeadingProps['tag'];
    size?: HeadingProps['size'];
    className?: string;
    isOrange?: boolean;
};

export const ColoredHeading = ({
    text,
    highlightedText,
    tag = 'h1',
    className,
    isOrange,
    size,
}: ColoredHeadingProps) => {
    if (!text) {
        return <></>;
    }

    const splitHighlight = nbsp(highlightedText)?.split(' ') ?? [];
    const coloredWords = splitHighlight.length;
    const splitArray = nbsp(text)!.split(' ');
    let renderArray: (string | JSX.Element | undefined)[] | undefined = [nbsp(text)];

    if (coloredWords > 0) {
        const startingIndex = splitArray.findIndex((item) => item.localeCompare(splitHighlight[0], 'cs') === 0);
        if (startingIndex > -1) {
            renderArray = [
                `${splitArray.slice(0, startingIndex).join(' ')} `,
                <span key="highlighted" className={isOrange ? styles.highlightedOrange : styles.highlighted}>
                    {nbsp(highlightedText)}
                </span>,
                ` ${splitArray.slice(startingIndex + coloredWords).join(' ')}`,
            ];
        }
    }

    return (
        <Heading tag={tag} className={className} size={size}>
            {renderArray}
        </Heading>
    );
};
