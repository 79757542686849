import React from 'react';
import { documentFragment$data } from '../../../relay/__generated__/documentFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { Icon } from '../../primitives/Icon/Icon';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Link } from '../../primitives/Link/Link';
import { trans } from '../../../utils/trans/trans';

export type DocumentProps = {
    document: OmitFragmentType<documentFragment$data>;
};

export const Document = ({ document }: DocumentProps) => {
    if (!document?.attributes) {
        return null;
    }

    const { file } = document.attributes;

    if (!file.data) {
        return null;
    }

    return (
        <Link
            className="py-8 border-b-lightGray border-b w-full tablet:py-4 tablet:flex tablet:items-center tablet:justify-between"
            route={{
                id: file.data.id,
                attributes: {
                    file,
                    badge: null,
                    bundle: null,
                    buttonType: 'Filled',
                    check: null,
                    news: null,
                    title: file.data.attributes?.name ?? '',
                    newTab: true,
                    page: null,
                    url: null,
                    params: null,
                    contactRequired: false,
                },
            }}
        >
            <div className="tablet:flex tablet:items-center">
                <Icon name="document-green" size="basic" />
                <div className="my-4 tablet:my-0 tablet:mx-4">
                    <Paragraph defaultStyles={false}>{document.attributes.title}</Paragraph>
                    <Paragraph className="text-darkGray text-sm">
                        {document.attributes.file.data?.attributes?.ext?.replace('.', '').toUpperCase()} (
                        {document.attributes.file.data?.attributes?.size} kB)
                    </Paragraph>
                </div>
            </div>
            <div className="flex items-center">
                <Icon name="download" className="mr-2" />
                <Paragraph defaultStyles={false} className="font-bold text-green">
                    {trans('download')}
                </Paragraph>
            </div>
        </Link>
    );
};
