import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { NewsDetailBlock_content$data } from './__generated__/NewsDetailBlock_content.graphql';
import { NewsDetail } from '../../components/blocks/NewsDetail/NewsDetail';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { getSlug } from '../../headless/utils';
import { newsDetailQuery$data } from '../../relay/__generated__/newsDetailQuery.graphql';
import { newsListQuery$data } from '../../relay/__generated__/newsListQuery.graphql';
import { getIdFromCustomItemType } from '../../utils/getIdFromCustomItemType';

export interface NewsDetailBlockStaticProps {
    item?: NonNullable<newsDetailQuery$data['item']>['data'];
    relatedArticles?: NonNullable<newsListQuery$data['items']>['data'];
}

export interface NewsDetailBlockContent extends OmitFragmentType<NewsDetailBlock_content$data> {
    __typename: 'ComponentContentNewsDetail';
}

export interface NewsDetailBlockProps extends NewsDetailBlockStaticProps {
    content: NewsDetailBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment NewsDetailBlock_content on ComponentContentNewsDetail {
        id
        moreArticles: More_articles
        backToList: Back_to_list {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
    }
`;

const NewsDetailBlock = ({ content, ...otherProps }: NewsDetailBlockProps): ReactElement => (
    <NewsDetail {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    NewsDetailBlock.getStaticProps = async ({
        locale,
        providers,
        context: { params, preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<NewsDetailBlockStaticProps> => {
        const provider = providers.news;

        if (!params?.slug) {
            return {};
        }

        const slug = getSlug(params.slug);
        if (!slug) {
            return {};
        }

        const { data } = await provider.find({
            locale,
            preview,
            offset: 0,
            limit: 1,
            filter: {
                Slug: {
                    eq: slug,
                },
            },
        });

        if (data.length !== 1 || !data[0].id) {
            const err = new Error() as unknown as Error & { code: string };
            err.code = 'ENOENT';
            throw err;
        }

        const article = await provider.findOne({
            locale,
            preview,
            id: data[0].id,
        });

        const { data: relatedArticles } = await provider.find({
            locale,
            preview,
            offset: 0,
            limit: 3,
            filter: {
                Published: {
                    lte: article?.attributes?.dateFrom,
                },
                id: {
                    ne: getIdFromCustomItemType(article?.id ?? ''),
                },
            },
        });

        return {
            item: article,
            relatedArticles,
        };
    };
}

NewsDetailBlock.whyDidYouRender = true;

export default NewsDetailBlock;
