import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { QuoteBlock_content$data } from './__generated__/QuoteBlock_content.graphql';
import { Quote } from '../../components/blocks/Quote/Quote';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuoteBlockStaticProps {}

export interface QuoteBlockContent extends OmitFragmentType<QuoteBlock_content$data> {
    __typename: 'ComponentContentQuote';
}

export interface QuoteBlockProps extends QuoteBlockStaticProps {
    content: QuoteBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment QuoteBlock_content on ComponentContentQuote {
        id
        quote: Quote {
            data {
                ...quoteFragment @relay(mask: false)
            }
        }
    }
`;

const QuoteBlock = ({ content, ...otherProps }: QuoteBlockProps): ReactElement => (
    <Quote {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
QuoteBlock.getStaticProps = async ({
    locale,
    providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<QuoteBlockStaticProps> => {
    const provider = providers.x;

    return {};
};
*/
}

QuoteBlock.whyDidYouRender = true;

export default QuoteBlock;
