import React, { HTMLProps, ReactElement, useState } from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import styles from './Checkbox.module.scss';
import { Markdown } from '../Markdown/Markdown';

export interface CheckboxProps extends HTMLProps<HTMLInputElement> {
    error?: FieldError;
}

const Checkbox = ({ label, id, error, ...props }: CheckboxProps): ReactElement => {
    const hasError = !!error;

    return (
        <div className={clsx(styles.container)}>
            <input className={clsx(styles.input, hasError && styles.hasError)} {...props} type="checkbox" id={id} />
            {label && (
                <label className={clsx(styles.label)} htmlFor={id}>
                    <Markdown content={label} />
                </label>
            )}
            {hasError && <p className={styles.error}>{error.message}</p>}
        </div>
    );
};

Checkbox.whyDidYouRender = true;

export { Checkbox };
