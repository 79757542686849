import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ButtonBlockContent, ButtonBlockProps } from '../../../blocks/ButtonBlock/ButtonBlock';
import { Heading } from '../../primitives/Heading/Heading';
import { Button as ButtonComponent } from '../../primitives/Button/Button';
import styles from './Button.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { getHrefFromRoute } from '../../../headless';
import convertButtonAppearance from '../../../utils/convertButtonAppearance';
import { Image } from '../../primitives/Image/Image';

export type ButtonProps = Omit<ButtonBlockProps, 'content'> & Omit<ButtonBlockContent, 'id' | '__typename'>;

export const Button = ({ className, buttonTitle, buttonDescription, route }: ButtonProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        <div
            className={clsx(
                'bg-darkGreen py-14 px-8 w-full text-white text-center rounded-4xl relative overflow-hidden',
                styles.content,
            )}
        >
            <div className={clsx('absolute bottom-0 right-0', styles.image)}>
                <Image
                    src="https://cms.scaut.com/uploads/green_shape_form_d0ac06abf4.png?updated_at=2022-09-06T17:45:05.957Z"
                    alt={'background shape'}
                    className={'object-cover object-right-bottom'}
                    fill
                />
            </div>
            <div className="relative">
                {buttonTitle && (
                    <Heading tag="h2" className="mb-4" size="md">
                        {buttonTitle}
                    </Heading>
                )}
                {buttonDescription && <Markdown content={buttonDescription} />}
                {route?.data && (
                    <ButtonComponent
                        href={getHrefFromRoute(route.data)}
                        appearance={convertButtonAppearance(route.data.attributes?.buttonType)}
                        className="mt-8"
                        contactRequired={!!route.data.attributes?.contactRequired}
                    >
                        {route.data.attributes?.title}
                    </ButtonComponent>
                )}
            </div>
        </div>
    </BlockWrapper>
);
