import React, { AnchorHTMLAttributes, memo, useReducer, useState } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';
import { nbsp } from '../../../headless/utils';
import { getHrefFromRoute, getLinkParamsFromPage, OmitFragmentType } from '../../../headless';
import { PageProps } from '../../../types/page';
import { appRouteFragment$data } from '../../../cms-strapi/relay/__generated__/appRouteFragment.graphql';
import { Portal } from 'react-portal';
import { useStack } from '@graphql-tools/load/typings/utils/helpers';
import { CollectForm } from '../../organisms/CollectForm/CollectForm';

type NonNullablePageProps = NonNullable<PageProps>;

export type LinkPageType = Pick<NonNullablePageProps, 'id'> & {
    attributes: Pick<NonNullable<NonNullablePageProps['attributes']>, 'url' | 'title'> | null;
};

export type LinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
    Omit<Partial<NextLinkProps>, 'href'> & {
        href?: string | UrlObject | null;
        page?: LinkPageType | null;
        route?: OmitFragmentType<appRouteFragment$data> | null;
        params?: Record<string, string | number> | ParsedUrlQuery;
        plain?: boolean;
        contactRequired?: boolean;
    };

const _Link = ({
    className,
    href,
    page,
    route,
    locale,
    params,
    children,
    target,
    plain,
    contactRequired,
    ...rest
}: LinkProps): JSX.Element => {
    const [portalVisible, togglePortalVisible] = useReducer((prev) => !prev, false);
    let realHref = '';

    if (typeof href === 'string') {
        if (!children) {
            throw new Error('Link with href without children!');
        }
        realHref = href;
    } else if (page) {
        realHref = getLinkParamsFromPage(page, params).as;
    } else if (route) {
        realHref = getHrefFromRoute(route);
    }

    const contactRequiredEvaluated = route?.attributes?.contactRequired ?? contactRequired ?? false;

    const attrs = {
        className,
        target: route && route.attributes?.newTab ? '_blank' : target,
        title: route?.attributes?.title || page?.attributes?.title || undefined,
        ...rest,
    };

    const realChildren = children || route?.attributes?.title || page?.attributes?.title;

    if (!contactRequiredEvaluated && (plain || (realHref && realHref.startsWith('http')) || target === '_blank')) {
        return (
            <a href={realHref} {...attrs}>
                {typeof realChildren === 'string' ? nbsp(realChildren) : realChildren}
            </a>
        );
    }

    return (
        <>
            <NextLink
                href={'/[[...slug]]'}
                as={contactRequiredEvaluated ? '' : realHref}
                locale={locale}
                onClick={(e) => {
                    if (contactRequiredEvaluated) {
                        e.preventDefault();
                        togglePortalVisible();
                        return;
                    }

                    if (realHref.includes('#')) {
                        const id = realHref.slice(realHref.indexOf('#') + 1);
                        const element = document.getElementById(id);
                        if (element) {
                            e.preventDefault();
                            element.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                                inline: 'center',
                            });
                        }
                    }

                    if (typeof attrs.onClick === 'function') {
                        return attrs.onClick(e);
                    }
                    return true;
                }}
                {...attrs}
                {...rest}
            >
                {typeof realChildren === 'string' ? nbsp(realChildren) : realChildren}
            </NextLink>
            {portalVisible && (
                <Portal>
                    <CollectForm onClose={togglePortalVisible} url={realHref} />
                </Portal>
            )}
        </>
    );
};

_Link.whyDidYouRender = true;

export const Link = memo(_Link);
