import React from 'react';
import clsx from 'clsx';
import styles from './Icon.module.scss';
import Alert from '../../../../public/svg/alert.svg';
import ArrowDown from '../../../../public/svg/arrowDown.svg';
import ArrowLeft from '../../../../public/svg/arrowLeft.svg';
import ArrowRight from '../../../../public/svg/arrowRight.svg';
import Bank from '../../../../public/svg/bank.svg';
import Check from '../../../../public/svg/check.svg';
import Company from '../../../../public/svg/company.svg';
import Cross from '../../../../public/svg/cross.svg';
import Download from '../../../../public/svg/download.svg';
import Email from '../../../../public/svg/email.svg';
import Facebook from '../../../../public/svg/facebook.svg';
import Instagram from '../../../../public/svg/instagram.svg';
import Integrity from '../../../../public/svg/integrity.svg';
import Linkedin from '../../../../public/svg/linkedin.svg';
import Logo from '../../../../public/svg/logo.svg';
import MapPin from '../../../../public/svg/map-pin.svg';
import Minus from '../../../../public/svg/minus.svg';
import Phone from '../../../../public/svg/phone.svg';
import Pin from '../../../../public/svg/pin.svg';
import Play from '../../../../public/svg/play.svg';
import Plus from '../../../../public/svg/plus.svg';
import Quote from '../../../../public/svg/quote.svg';
import Twitter from '../../../../public/svg/twitter.svg';
import Youtube from '../../../../public/svg/youtube.svg';

import AdversMediaV01Green from '../../../../public/svg/advers-media-V01-green.svg';
import AdversMediaV01Orange from '../../../../public/svg/advers-media-V01-orange.svg';
import AdversMediaV02Green from '../../../../public/svg/advers-media-V02-green.svg';
import AdversMediaV02Orange from '../../../../public/svg/advers-media-V02-orange.svg';
import BusinessGreen from '../../../../public/svg/business-green.svg';
import BusinessOrange from '../../../../public/svg/business-orange.svg';
import CertificationGreen from '../../../../public/svg/certification-green.svg';
import CertificationOrange from '../../../../public/svg/certification-orange.svg';
import CompanyGreen from '../../../../public/svg/company-green.svg';
import CompanyOrange from '../../../../public/svg/company-orange.svg';
import ConflictGreen from '../../../../public/svg/conflict-green.svg';
import ConflictOrange from '../../../../public/svg/conflict-orange.svg';
import CredibilityGreen from '../../../../public/svg/credibility-green.svg';
import CredibilityOrange from '../../../../public/svg/credibility-orange.svg';
import DebtlessnessGreen from '../../../../public/svg/debtlessness-green.svg';
import DebtlessnessOrange from '../../../../public/svg/debtlessness-orange.svg';
import DocumentGreen from '../../../../public/svg/document-green.svg';
import DocumentOrange from '../../../../public/svg/document-orange.svg';
import EducationGreen from '../../../../public/svg/education-green.svg';
import EducationOrange from '../../../../public/svg/education-orange.svg';
import EffectiveProcessGreen from '../../../../public/svg/effective-process-green.svg';
import EffectiveProcessOrange from '../../../../public/svg/effective-process-orange.svg';
import EffectiveProcessV02Green from '../../../../public/svg/effective-process-v02-green.svg';
import EffectiveProcessV02Orange from '../../../../public/svg/effective-process-v02-orange.svg';
import EmployeesGreen from '../../../../public/svg/employees-green.svg';
import EmployeesOrange from '../../../../public/svg/employees-orange.svg';
import GDPRGreen from '../../../../public/svg/gdpr-green.svg';
import GDPROrange from '../../../../public/svg/gdpr-orange.svg';
import HistoryGreen from '../../../../public/svg/history-green.svg';
import HistoryOrange from '../../../../public/svg/history-orange.svg';
import ImpunityAndIntegrityGreen from '../../../../public/svg/impunity-and-integrity-green.svg';
import ImpunityAndIntegrityOrange from '../../../../public/svg/impunity-and-integrity-orange.svg';
import MediaMonitoringGreen from '../../../../public/svg/media-monitoring-green.svg';
import MediaMonitoringOrange from '../../../../public/svg/media-monitoring-orange.svg';
import PersonalDocumentsGreen from '../../../../public/svg/personal-documents-green.svg';
import PersonalDocumentsOrange from '../../../../public/svg/personal-documents-orange.svg';
import PoliceBadgeGreen from '../../../../public/svg/police-badge-green.svg';
import PoliceBadgeOrange from '../../../../public/svg/police-badge-orange.svg';
import RatingGreen from '../../../../public/svg/rating-green.svg';
import RatingOrange from '../../../../public/svg/rating-orange.svg';
import ReferePeopleGreen from '../../../../public/svg/refere-people-green.svg';
import ReferePeopleOrange from '../../../../public/svg/refere-people-orange.svg';
import SaveGreen from '../../../../public/svg/save-green.svg';
import SaveOrange from '../../../../public/svg/save-orange.svg';
import SaveMoneyAndTimeGreen from '../../../../public/svg/save-money-and-time-green.svg';
import SaveMoneyAndTimeOrange from '../../../../public/svg/save-money-and-time-orange.svg';
import ShieldGreen from '../../../../public/svg/shield-green.svg';
import ShieldOrange from '../../../../public/svg/shield-orange.svg';
import SpeedGreen from '../../../../public/svg/speed-green.svg';
import SpeedOrange from '../../../../public/svg/speed-orange.svg';

export type Icons =
    | 'alert'
    | 'arrowDown'
    | 'arrowLeft'
    | 'arrowRight'
    | 'bank'
    | 'check'
    | 'company'
    | 'cross'
    | 'download'
    | 'email'
    | 'facebook'
    | 'instagram'
    | 'integrity'
    | 'linkedin'
    | 'logo'
    | 'mapPin'
    | 'minus'
    | 'phone'
    | 'pin'
    | 'play'
    | 'plus'
    | 'quote'
    | 'twitter'
    | 'youtube'
    | 'advers-media-v01-green'
    | 'advers-media-v01-orange'
    | 'advers-media-v02-green'
    | 'advers-media-v02-orange'
    | 'business-green'
    | 'business-orange'
    | 'certification-green'
    | 'certification-orange'
    | 'company-green'
    | 'company-orange'
    | 'conflict-green'
    | 'conflict-orange'
    | 'credibility-green'
    | 'credibility-orange'
    | 'debtlessness-green'
    | 'debtlessness-orange'
    | 'document-green'
    | 'document-orange'
    | 'education-green'
    | 'education-orange'
    | 'effective-process-green'
    | 'effective-process-orange'
    | 'effective-process-v02-green'
    | 'effective-process-v02-orange'
    | 'employees-green'
    | 'employees-orange'
    | 'gdpr-green'
    | 'gdpr-orange'
    | 'history-green'
    | 'history-orange'
    | 'impunity-and-integrity-green'
    | 'impunity-and-integrity-orange'
    | 'media-monitoring-green'
    | 'media-monitoring-orange'
    | 'personal-documents-green'
    | 'personal-documents-orange'
    | 'police-badge-green'
    | 'police-badge-orange'
    | 'rating-green'
    | 'rating-orange'
    | 'refere-people-green'
    | 'refere-people-orange'
    | 'save-green'
    | 'save-orange'
    | 'save-money-and-time-green'
    | 'save-money-and-time-orange'
    | 'shield-green'
    | 'shield-orange'
    | 'speed-green'
    | 'speed-orange'
    | null;

export interface IconProps {
    name: Icons;
    className?: string;
    size?: 'sm' | 'md' | 'lg' | 'basic' | 'xs';
}

const Icon = ({ name, className, size = 'sm' }: IconProps): JSX.Element => {
    const renderIcon = (name: Icons): JSX.Element => {
        switch (name) {
            case 'alert':
                return <Alert />;
            case 'arrowDown':
                return <ArrowDown />;
            case 'arrowLeft':
                return <ArrowLeft />;
            case 'arrowRight':
                return <ArrowRight />;
            case 'bank':
                return <Bank />;
            case 'check':
                return <Check />;
            case 'company':
                return <Company />;
            case 'cross':
                return <Cross />;
            case 'download':
                return <Download />;
            case 'email':
                return <Email />;
            case 'facebook':
                return <Facebook />;
            case 'instagram':
                return <Instagram />;
            case 'integrity':
                return <Integrity />;
            case 'linkedin':
                return <Linkedin />;
            case 'logo':
                return <Logo />;
            case 'mapPin':
                return <MapPin />;
            case 'minus':
                return <Minus />;
            case 'phone':
                return <Phone />;
            case 'pin':
                return <Pin />;
            case 'play':
                return <Play />;
            case 'plus':
                return <Plus />;
            case 'quote':
                return <Quote />;
            case 'twitter':
                return <Twitter />;
            case 'youtube':
                return <Youtube />;
            case 'advers-media-v01-green':
                return <AdversMediaV01Green />;
            case 'advers-media-v01-orange':
                return <AdversMediaV01Orange />;
            case 'advers-media-v02-green':
                return <AdversMediaV02Green />;
            case 'advers-media-v02-orange':
                return <AdversMediaV02Orange />;
            case 'business-green':
                return <BusinessGreen />;
            case 'business-orange':
                return <BusinessOrange />;
            case 'certification-green':
                return <CertificationGreen />;
            case 'certification-orange':
                return <CertificationOrange />;
            case 'company-green':
                return <CompanyGreen />;
            case 'company-orange':
                return <CompanyOrange />;
            case 'conflict-green':
                return <ConflictGreen />;
            case 'conflict-orange':
                return <ConflictOrange />;
            case 'credibility-green':
                return <CredibilityGreen />;
            case 'credibility-orange':
                return <CredibilityOrange />;
            case 'debtlessness-green':
                return <DebtlessnessGreen />;
            case 'debtlessness-orange':
                return <DebtlessnessOrange />;
            case 'document-green':
                return <DocumentGreen />;
            case 'document-orange':
                return <DocumentOrange />;
            case 'education-green':
                return <EducationGreen />;
            case 'education-orange':
                return <EducationOrange />;
            case 'effective-process-green':
                return <EffectiveProcessGreen />;
            case 'effective-process-orange':
                return <EffectiveProcessOrange />;
            case 'effective-process-v02-green':
                return <EffectiveProcessV02Green />;
            case 'effective-process-v02-orange':
                return <EffectiveProcessV02Orange />;
            case 'employees-green':
                return <EmployeesGreen />;
            case 'employees-orange':
                return <EmployeesOrange />;
            case 'gdpr-green':
                return <GDPRGreen />;
            case 'gdpr-orange':
                return <GDPROrange />;
            case 'history-green':
                return <HistoryGreen />;
            case 'history-orange':
                return <HistoryOrange />;
            case 'impunity-and-integrity-green':
                return <ImpunityAndIntegrityGreen />;
            case 'impunity-and-integrity-orange':
                return <ImpunityAndIntegrityOrange />;
            case 'media-monitoring-green':
                return <MediaMonitoringGreen />;
            case 'media-monitoring-orange':
                return <MediaMonitoringOrange />;
            case 'personal-documents-green':
                return <PersonalDocumentsGreen />;
            case 'personal-documents-orange':
                return <PersonalDocumentsOrange />;
            case 'police-badge-green':
                return <PoliceBadgeGreen />;
            case 'police-badge-orange':
                return <PoliceBadgeOrange />;
            case 'rating-green':
                return <RatingGreen />;
            case 'rating-orange':
                return <RatingOrange />;
            case 'refere-people-green':
                return <ReferePeopleGreen />;
            case 'refere-people-orange':
                return <ReferePeopleOrange />;
            case 'save-green':
                return <SaveGreen />;
            case 'save-orange':
                return <SaveOrange />;
            case 'save-money-and-time-green':
                return <SaveMoneyAndTimeGreen />;
            case 'save-money-and-time-orange':
                return <SaveMoneyAndTimeOrange />;
            case 'shield-green':
                return <ShieldGreen />;
            case 'shield-orange':
                return <ShieldOrange />;
            case 'speed-green':
                return <SpeedGreen />;
            case 'speed-orange':
                return <SpeedOrange />;
            default:
                return <></>;
        }
    };
    return <div className={clsx(styles.wrapper, styles[size], className)}>{name && renderIcon(name)}</div>;
};

Icon.whyDidYouRender = true;

export { Icon };
