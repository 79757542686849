import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { NewsListBlock_content$data } from './__generated__/NewsListBlock_content.graphql';
import { NewsList } from '../../components/blocks/NewsList/NewsList';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { newsListQuery$data } from '../../relay/__generated__/newsListQuery.graphql';
import { NEWS_LIMIT } from '../../constants';

export interface NewsListBlockStaticProps {
    articles: NonNullable<newsListQuery$data['items']>['data'];
    articlesCount: number;
}

export interface NewsListBlockContent extends OmitFragmentType<NewsListBlock_content$data> {
    __typename: 'ComponentContentNewsList';
}

export interface NewsListBlockProps extends NewsListBlockStaticProps {
    content: NewsListBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment NewsListBlock_content on ComponentContentNewsList {
        id
        loadMore: Load_more
        moreArticles: more_articles
    }
`;

const NewsListBlock = ({ content, ...otherProps }: NewsListBlockProps): ReactElement => (
    <NewsList {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    NewsListBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<NewsListBlockStaticProps> => {
        const provider = providers.news;

        const { data, count } = await provider.find({
            preview,
            locale,
            offset: 0,
            limit: NEWS_LIMIT,
        });

        return {
            articles: data,
            articlesCount: count,
        };
    };
}

NewsListBlock.whyDidYouRender = true;

export default NewsListBlock;
