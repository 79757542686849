import React, { HTMLProps, ReactElement, useCallback, useState, FocusEvent } from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';
import { Icon, Icons } from '../Icon/Icon';
import styles from './Input.module.scss';

export interface InputProps extends HTMLProps<HTMLInputElement> {
    readonly icon?: Icons;
    error?: FieldError;
}

const Input = ({ label, icon, type, id, error, onBlur, ...props }: InputProps): ReactElement => {
    const [focused, setFocused] = useState(false);

    const handleFocused = useCallback(() => {
        setFocused(true);
    }, []);

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            onBlur?.(e);
            !e.target.value && setFocused(false);
        },
        [onBlur],
    );

    const hasIcon = !!(icon && icon.length > 0);
    const hasError = !!error;

    return (
        <div className={clsx(styles.container, hasIcon && styles.hasIcon)}>
            {label && (
                <label className={clsx(styles.label, focused && styles.labelFocused)} htmlFor={id}>
                    {/*{type === 'checkbox' && <Icon name="tick" className={styles.checkboxIcon} />}*/}
                    {label}
                </label>
            )}
            {icon && <Icon name={icon} />}
            <input
                className={clsx(styles.input, focused && styles.inputFocused, hasError && styles.hasError)}
                onFocus={handleFocused}
                onBlur={handleBlur}
                {...props}
                type={type}
                id={id}
            />
            {hasError && <p className={styles.error}>{error.message}</p>}
        </div>
    );
};

Input.whyDidYouRender = true;

export { Input };
