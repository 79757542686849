import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { InfotextBlock_content$data } from './__generated__/InfotextBlock_content.graphql';
import { Infotext } from '../../components/blocks/Infotext/Infotext';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InfotextBlockStaticProps {}

export interface InfotextBlockContent extends OmitFragmentType<InfotextBlock_content$data> {
    __typename: 'ComponentContentInfotext';
}

export interface InfotextBlockProps extends InfotextBlockStaticProps {
    content: InfotextBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment InfotextBlock_content on ComponentContentInfotext {
        id
        infotextTitle: Title
        infotextDescription: Description
        backgroundImagePosition: Background_image_position
        wideImage: Wide_image
        route {
            data {
                ...appRouteFragment @relay(mask: false)
            }
        }
        animationFile {
            data {
                ...appFileFragment @relay(mask: false)
            }
        }
        infotextVideo: video {
            data {
                ...appVideoFragment @relay(mask: false)
            }
        }
    }
`;

const InfotextBlock = ({ content, ...otherProps }: InfotextBlockProps): ReactElement => (
    <Infotext {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    // InfotextBlock.getStaticProps = async ({
    //     block,
    // }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<InfotextBlockStaticProps> => {
    //     return {};
    // };
}

InfotextBlock.whyDidYouRender = true;

export default InfotextBlock;
