import { Icon } from '../../primitives/Icon/Icon';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import React from 'react';
import { CheckDetailProps } from '../../blocks/CheckDetail/CheckDetail';
import clsx from 'clsx';

export type CheckCardRowProps = NonNullable<NonNullable<CheckDetailProps['cardRow']>[number]>;

export const CheckCardRow = ({ title, id, state, showState }: CheckCardRowProps) => {
    const isConsideration = state === 'consideration';
    const isFinding = state === 'finding';
    const icon = isFinding ? 'cross' : isConsideration ? 'alert' : 'check';

    return (
        <div key={id} className="flex items-center">
            <div
                className={clsx(
                    'rounded-full mr-2 w-4 h-4 flex items-center justify-center flex-shrink-0',
                    showState && isFinding && 'bg-red',
                    showState && isConsideration && 'bg-orange',
                    showState && !isFinding && !isConsideration && 'bg-green',
                )}
            >
                {showState && <Icon name={icon} className="text-white" size="xs" />}
            </div>
            <Paragraph className={clsx(isConsideration && 'text-orange', isFinding && 'text-red')}>{title}</Paragraph>
        </div>
    );
};
