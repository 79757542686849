import React, { ReactElement } from 'react';
import { BundleDetailBlockContent, BundleDetailBlockProps } from '../../../blocks/BundleDetailBlock/BundleDetailBlock';
import { CommonDetailHero } from '../../organisms/CommonDetailHero/CommonDetailHero';

export type BundleDetailProps = Omit<BundleDetailBlockProps, 'content'> &
    Omit<BundleDetailBlockContent, 'id' | '__typename'>;

export const BundleDetail = ({
    backToBundles,
    routes,
    item,
    app,
    bundleState,
    downloadReport,
    cardRow,
}: BundleDetailProps): ReactElement => {
    if (!item?.attributes || !app) {
        return <></>;
    }

    return (
        <CommonDetailHero
            icon={null}
            isOrange={false}
            {...item.attributes}
            back={backToBundles?.data}
            routes={routes?.data}
            app={app}
            defaultCardRow={cardRow}
            downloadReport={downloadReport}
            defaultState={bundleState}
        />
    );
};
