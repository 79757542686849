import React from 'react';
import { checkFragment$data } from '../../../relay/__generated__/checkFragment.graphql';
import { Icon } from '../../primitives/Icon/Icon';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { convertIconEnumToIconNameMap } from '../../../utils/convertIconEnumToIconNameMap';
import { OmitFragmentType } from '../../../headless';
import { pageBaseFragment$data } from '../../../relay/__generated__/pageBaseFragment.graphql';
import { Link } from '../../primitives/Link/Link';
import { Heading } from '../../primitives/Heading/Heading';
import { useRouter } from 'next/router';
import { trans } from '../../../utils/trans/trans';

export type CheckProps = NonNullable<checkFragment$data['attributes']> & {
    detailPage?: OmitFragmentType<pageBaseFragment$data> | null;
};

export const Check = ({ icon, title, description, slug, detailPage }: CheckProps) => {
    const { locale } = useRouter();
    return (
        <Link
            page={detailPage}
            params={{ slug }}
            className="flex flex-col justify-between pl-10 border-l border-lightGray"
        >
            <div>
                {icon?.data?.attributes?.title && (
                    <Icon name={convertIconEnumToIconNameMap[icon.data.attributes.title]} size="lg" className="mb-10" />
                )}
                <Heading tag="h3" size="sm" className="uppercase mb-4">
                    {title}
                </Heading>
                <Markdown content={description} />
            </div>

            <div className="flex items-center text-green mt-4">
                <Paragraph className="mr-2 font-bold">{trans('more')}</Paragraph>
                <Icon name="arrowRight" />
            </div>
        </Link>
    );
};
