/**
 * @generated SignedSource<<9abab048617b3b917e8bd4a042ef6ed7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FaQsBlock_content$data = {
  readonly faqs: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly description: string;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly faqsDescription: string | null;
  readonly faqsTitle: string | null;
  readonly id: string;
  readonly " $fragmentType": "FaQsBlock_content";
};
export type FaQsBlock_content$key = {
  readonly " $data"?: FaQsBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"FaQsBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FaQsBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "faqsTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "faqsDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FaqRelationResponseCollection",
      "kind": "LinkedField",
      "name": "faqs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FaqEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Faq",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": "title",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Title",
                  "storageKey": null
                },
                {
                  "alias": "description",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentFaQs",
  "abstractKey": null
};
})();

(node as any).hash = "d7c7ca90a7eac1657bf15b0c5726ace7";

export default node;
