import React from 'react';
import { benefitFragment$data } from '../../../relay/__generated__/benefitFragment.graphql';
import { Icon } from '../../primitives/Icon/Icon';
import { Heading } from '../../primitives/Heading/Heading';
import { convertIconEnumToIconNameMap } from '../../../utils/convertIconEnumToIconNameMap';
import { Markdown } from '../../primitives/Markdown/Markdown';

export type BenefitProps = NonNullable<benefitFragment$data['attributes']>;

export const Benefit = ({ icon, title, description }: BenefitProps) => (
    <div className="px-8 border-l border-lightGray tablet:px-10">
        {icon?.data?.attributes?.title && (
            <Icon name={convertIconEnumToIconNameMap[icon.data.attributes.title]} size="lg" className="mb-10" />
        )}
        <Heading tag={'h3'} size="sm" className="mb-4">
            {title.toUpperCase()}
        </Heading>
        {description && <Markdown content={description} />}
    </div>
);
