import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { MapBlockContent, MapBlockProps } from '../../../blocks/MapBlock/MapBlock';
import styles from './Map.module.scss';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { Markdown } from '../../primitives/Markdown/Markdown';
import map from '../../../animations/world-map.gif';
import { Image } from '../../primitives/Image/Image';

export type MapProps = Omit<MapBlockProps, 'content'> & Omit<MapBlockContent, 'id' | '__typename'>;

export const Map = ({ className, mapDescription, mapTitle, highlightedText }: MapProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        <div className={clsx('h-108 w-full relative overflow-hidden', styles.mapWrapper)}>
            <div className="absolute top-0 left-1/2 -translate-x-1/2 h-full w-203 large-desktop:left-auto large-desktop:right-0 large-desktop:translate-x-0">
                <Image src={map} alt={'map animation'} />
            </div>
        </div>
        <div className={styles.content}>
            <ColoredHeading tag="h2" text={mapTitle} highlightedText={highlightedText} />
            {mapDescription && (
                <div className="mt-10">
                    <Markdown content={mapDescription} />
                </div>
            )}
        </div>
    </BlockWrapper>
);
