/**
 * @generated SignedSource<<187c5d40abb0d8c89b13c23cfe88293b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ENUM_COMPONENTCHECKDETAILCARDROW_STATE = "consideration" | "finding" | "ok" | "%future added value";
export type ENUM_COMPONENTCONTENTBUNDLEDETAIL_STATE = "consideration" | "finding" | "ok" | "%future added value";
export type ENUM_ROUTE_BUTTON_TYPE = "Filled" | "Outline" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BundleDetailBlock_content$data = {
  readonly backToBundles: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly bundleState: ENUM_COMPONENTCONTENTBUNDLEDETAIL_STATE;
  readonly cardRow: ReadonlyArray<{
    readonly id: string;
    readonly showState: boolean | null;
    readonly state: ENUM_COMPONENTCHECKDETAILCARDROW_STATE;
    readonly title: string;
  } | null>;
  readonly downloadReport: string;
  readonly id: string;
  readonly routes: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "BundleDetailBlock_content";
};
export type BundleDetailBlock_content$key = {
  readonly " $data"?: BundleDetailBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"BundleDetailBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "title",
  "args": null,
  "kind": "ScalarField",
  "name": "Title",
  "storageKey": null
},
v2 = {
  "alias": "url",
  "args": null,
  "kind": "ScalarField",
  "name": "URL",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
],
v5 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Route",
    "kind": "LinkedField",
    "name": "attributes",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": "badge",
        "args": null,
        "kind": "ScalarField",
        "name": "Badge",
        "storageKey": null
      },
      {
        "alias": "buttonType",
        "args": null,
        "kind": "ScalarField",
        "name": "Button_type",
        "storageKey": null
      },
      {
        "alias": "file",
        "args": null,
        "concreteType": "UploadFileEntityResponse",
        "kind": "LinkedField",
        "name": "File",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadFileEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadFile",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "newTab",
        "args": null,
        "kind": "ScalarField",
        "name": "New_tab",
        "storageKey": null
      },
      {
        "alias": "news",
        "args": null,
        "concreteType": "NewsEntityResponse",
        "kind": "LinkedField",
        "name": "News",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "News",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "slug",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "Slug",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "page",
        "args": null,
        "concreteType": "PageEntityResponse",
        "kind": "LinkedField",
        "name": "Page",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckEntityResponse",
        "kind": "LinkedField",
        "name": "check",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Check",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BundleEntityResponse",
        "kind": "LinkedField",
        "name": "bundle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Bundle",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "params",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contactRequired",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BundleDetailBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RouteEntityResponse",
      "kind": "LinkedField",
      "name": "backToBundles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RouteEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RouteRelationResponseCollection",
      "kind": "LinkedField",
      "name": "routes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RouteEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadReport",
      "storageKey": null
    },
    {
      "alias": "bundleState",
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComponentCheckDetailCardRow",
      "kind": "LinkedField",
      "name": "cardRow",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentBundleDetail",
  "abstractKey": null
};
})();

(node as any).hash = "df510af3c540dbc5091e84d446e30d33";

export default node;
