import { pricingFragment$data } from '../../../relay/__generated__/pricingFragment.graphql';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { useRouter } from 'next/router';
import { formatPrice } from '../../../utils/formatPrice';

export type PricingProps = NonNullable<pricingFragment$data['attributes']>;

export const Pricing = ({ price, title, unit, currency, description }: PricingProps) => {
    const { locale } = useRouter();
    return (
        <div className="py-8 border-b border-lightGray tablet:flex justify-between items-center">
            <div className="mb-4 tablet:w-3/5 tablet:mb-0 tablet-landscape:flex tablet-landscape:items-center desktop:w-4/5">
                <Paragraph
                    className="font-bold uppercase mb-4 tablet-landscape:shrink-0 tablet-landscape:w-2/5 tablet-landscape:mb-0 tablet-landscape:pr-4 large-desktop:w-1/3"
                    defaultStyles={false}
                >
                    {title}
                </Paragraph>
                {description && (
                    <div>
                        <Markdown content={description} />
                    </div>
                )}
            </div>
            <div>
                <Paragraph className="tablet:text-right">
                    <span className="font-bold">{formatPrice(price, currency, locale)}</span> / {unit}
                </Paragraph>
            </div>
        </div>
    );
};
