import React from 'react';
import { referenceFragment$data } from '../../../relay/__generated__/referenceFragment.graphql';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Author } from '../Author/Author';

export type ReferenceProps = NonNullable<referenceFragment$data['attributes']>;

export const Reference = ({ title, person }: ReferenceProps) => (
    <div>
        <Paragraph className="text-center">{title}</Paragraph>
        {person?.data?.attributes && (
            <div className="mt-8 flex justify-center">
                <Author author={person.data.attributes} />
            </div>
        )}
    </div>
);
