/**
 * @generated SignedSource<<20f8a3b4e96cf906af61322dbe3a392a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteBlock_content$data = {
  readonly id: string;
  readonly quote: {
    readonly data: {
      readonly attributes: {
        readonly author: {
          readonly data: {
            readonly attributes: {
              readonly image: {
                readonly data: {
                  readonly attributes: {
                    readonly alternativeText: string | null;
                    readonly formats: any | null;
                    readonly height: number | null;
                    readonly name: string;
                    readonly placeholder: string | null;
                    readonly url: string;
                    readonly width: number | null;
                  } | null;
                } | null;
              };
              readonly occupation: string | null;
              readonly title: string;
            } | null;
          } | null;
        } | null;
        readonly content: string;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "QuoteBlock_content";
};
export type QuoteBlock_content$key = {
  readonly " $data"?: QuoteBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "quote",
      "args": null,
      "concreteType": "QuoteEntityResponse",
      "kind": "LinkedField",
      "name": "Quote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuoteEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Quote",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": "author",
                  "args": null,
                  "concreteType": "PersonEntityResponse",
                  "kind": "LinkedField",
                  "name": "Author",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersonEntity",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "attributes",
                          "plural": false,
                          "selections": [
                            {
                              "alias": "image",
                              "args": null,
                              "concreteType": "UploadFileEntityResponse",
                              "kind": "LinkedField",
                              "name": "Image",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "UploadFileEntity",
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "UploadFile",
                                      "kind": "LinkedField",
                                      "name": "attributes",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "name",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "url",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "placeholder",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "alternativeText",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "height",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "width",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "formats",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": "title",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "Title",
                              "storageKey": null
                            },
                            {
                              "alias": "occupation",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "Occupation",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentQuote",
  "abstractKey": null
};

(node as any).hash = "0958e520614c185f5ee4f9abe5909ce0";

export default node;
