import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { BenefitsBlock_content$data } from './__generated__/BenefitsBlock_content.graphql';
import { Benefits } from '../../components/blocks/Benefits/Benefits';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BenefitsBlockStaticProps {}

export interface BenefitsBlockContent extends OmitFragmentType<BenefitsBlock_content$data> {
    __typename: 'ComponentContentBenefits';
}

export interface BenefitsBlockProps extends BenefitsBlockStaticProps {
    content: BenefitsBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment BenefitsBlock_content on ComponentContentBenefits {
        id
        benefitsTitle: Title
        benefitsDescription: Description
        highlightedText
        benefits: Benefits {
            data {
                ...benefitFragment @relay(mask: false)
            }
        }
    }
`;

const BenefitsBlock = ({ content, ...otherProps }: BenefitsBlockProps): ReactElement => (
    <Benefits {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
BenefitsBlock.getStaticProps = async ({
    locale,
    providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<BenefitsBlockStaticProps> => {
    const provider = providers.x;

    return {};
};
*/
}

BenefitsBlock.whyDidYouRender = true;

export default BenefitsBlock;
