import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import styles from './Newsletter.module.scss';
import { AppContextProps } from '../../../headless';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { Textarea } from '../../primitives/Textarea/Textarea';
import { useRouter } from 'next/router';
import { Checkbox } from '../../primitives/Checkbox/Checkbox';
import axios from 'axios';
import { useIsInViewport } from '../../../hooks/useIsInViewport';
import { trackFormSend } from '../../../utils/gtm';
import { trans } from '../../../utils/trans/trans';
import { FormBase } from '../../molecule/FormBase/FormBase';

export type NewsletterProps = {
    app: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
};

export type FormData = {
    name: string;
    company: string;
    email: string;
    message: string;
    gdpr: boolean;
    phoneNumber: string;
};

export const Newsletter = ({ className, app }: NewsletterProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const [animate, setAnimate] = useState(false);
    const isInViewport = useIsInViewport(ref);
    const { locale } = useRouter();
    const form = useForm<FormData>();
    const {
        formState: { errors },
        control,
        setError,
        setValue,
    } = form;
    const requiredText = trans('fieldRequired');

    const onSubmit = useCallback(async (data: FormData) => {
        const res = await axios.post('/api/form/send', {
            data,
        });

        if (res.status === 200) {
            trackFormSend();
        }

        return res;
    }, []);

    useEffect(() => {
        if (isInViewport && !animate) {
            setAnimate(true);
        }
    }, [isInViewport, animate]);

    if (!app.webSetting?.data?.attributes || !locale) {
        return <></>;
    }

    const { newsletterConsent, newsletterDescription, newsletterSubmit, newsletterTitle, newsletterShape } =
        app.webSetting.data.attributes;

    return (
        <BlockWrapper ref={ref} className={clsx(styles.blockWrapper, className)} id="lead-form">
            <FormProvider {...form}>
                <FormBase<FormData>
                    title={newsletterTitle}
                    buttonLabel={newsletterSubmit}
                    description={newsletterDescription}
                    onSubmit={onSubmit}
                    shape={newsletterShape?.data}
                    animate={animate}
                    gdpr={
                        <Controller
                            render={({ field: { value, ...rest } }) => (
                                <Checkbox
                                    {...rest}
                                    type="checkbox"
                                    id="gdpr"
                                    error={errors['gdpr']}
                                    label={newsletterConsent}
                                    onChange={(val) => {
                                        setValue('gdpr', (val.target as HTMLInputElement).checked);
                                        setError('gdpr', {});
                                    }}
                                />
                            )}
                            rules={{ required: requiredText }}
                            control={control}
                            name="gdpr"
                        />
                    }
                    passedStyles={{
                        block: styles.blockInner,
                        form: styles.form,
                        shape: styles.shape,
                        animatedShape: styles.animated,
                    }}
                >
                    <Controller
                        render={({ field }) => (
                            <Textarea {...field} id="message" error={errors['message']} label={trans('message')} />
                        )}
                        rules={{ required: requiredText }}
                        control={control}
                        name="message"
                    />
                </FormBase>
            </FormProvider>
        </BlockWrapper>
    );
};
