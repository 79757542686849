/**
 * @generated SignedSource<<b331719889b78d8ec84e2323aad4a438>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChecksBlock_content$data = {
  readonly checksDescription: string | null;
  readonly checksTitle: string;
  readonly id: string;
  readonly " $fragmentType": "ChecksBlock_content";
};
export type ChecksBlock_content$key = {
  readonly " $data"?: ChecksBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChecksBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChecksBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "checksTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "checksDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "ComponentContentChecks",
  "abstractKey": null
};

(node as any).hash = "f166d8665f82f951c49bb7935eaf5b17";

export default node;
