import React from 'react';
import dayjs from 'dayjs';
import styles from './ArticleCard.module.scss';
import { newsFragment$data } from '../../../relay/__generated__/newsFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { pageBaseFragment$data } from '../../../relay/__generated__/pageBaseFragment.graphql';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Heading } from '../../primitives/Heading/Heading';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Link } from '../../primitives/Link/Link';
import { Image } from '../../primitives/Image/Image';
import { DATE_FORMAT } from '../../../constants';

export type ArticleCardProps = OmitFragmentType<newsFragment$data> & {
    detailPage?: OmitFragmentType<pageBaseFragment$data> | null;
};

export const ArticleCard = ({ image, title, slug, perex, dateFrom, detailPage }: ArticleCardProps) => {
    if (!detailPage) {
        return null;
    }

    return (
        <Link page={detailPage} params={{ slug }} className={styles.wrapper}>
            {image?.data?.attributes && (
                <div className="mb-10 h-50 relative tablet:h-60 tablet:mb-0">
                    <Image image={image.data.attributes} fill className={'object-center object-cover'} />
                </div>
            )}
            <div>
                <Paragraph className="mb-4">{dayjs(dateFrom).format(DATE_FORMAT)}</Paragraph>
                <Heading tag="h3" size="sm" className="mb-4 uppercase">
                    {title}
                </Heading>
                {perex && <Markdown content={perex} />}
            </div>
        </Link>
    );
};
