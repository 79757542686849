import clsx from 'clsx';
import { Image } from '../../primitives/Image/Image';
import { Heading } from '../../primitives/Heading/Heading';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { Input } from '../../primitives/Input/Input';
import { trans } from '../../../utils/trans/trans';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { PhoneInput } from '../../primitives/PhoneInput/PhoneInput';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import { Loader } from '../../primitives/Loader/Loader';
import { Button, ButtonAppearance } from '../../primitives/Button/Button';
import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { appImageFragment$data } from '../../../cms-strapi/relay/__generated__/appImageFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { ButtonIcon } from '../../primitives/ButtonIcon/ButtonIcon';

export type FormDataBase = {
    name: string;
    company: string;
    email: string;
    phoneNumber: string;
};

type PassedStyles = {
    block: string;
    form: string;
    shape: string;
    animatedShape: string;
};

export type FormBaseProps<T> = {
    title: string;
    description?: string;
    buttonLabel: string;
    children?: JSX.Element;
    gdpr?: JSX.Element;
    onSubmit: (data: T) => Promise<AxiosResponse>;
    shape?: OmitFragmentType<appImageFragment$data> | null;
    animate?: boolean;
    onClose?: () => void;
    passedStyles: PassedStyles;
};

export const FormBase = <T extends FormDataBase>({
    title,
    description,
    buttonLabel,
    children,
    gdpr,
    onSubmit,
    shape,
    animate,
    onClose,
    passedStyles,
}: FormBaseProps<T>) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useFormContext<FormDataBase>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [globalError, setGlobalError] = useState(false);
    const requiredText = trans('fieldRequired');

    const onSubmitLocal: SubmitHandler<T> = useCallback(
        async (data) => {
            setLoading(true);
            setSuccess(false);
            setGlobalError(false);

            const res = await onSubmit(data);

            if (res.status === 200) {
                setSuccess(true);
            } else {
                setGlobalError(true);
            }

            setLoading(false);
        },
        [onSubmit],
    );

    return (
        <div className={passedStyles.block}>
            {shape?.attributes && (
                <div className={clsx(passedStyles.shape, animate && passedStyles.animatedShape)}>
                    <div className="w-full h-full">
                        <Image image={shape.attributes} fill className={'object-cover object-bottom'} />
                    </div>
                </div>
            )}
            {onClose && (
                <div className={'absolute right-0 top-4 tablet:top-8 tablet:right-8'}>
                    <ButtonIcon icon={'cross'} appearance={ButtonAppearance.outlineWhite} onClick={onClose} />
                </div>
            )}
            <div className="w-full relative">
                <Heading tag={'h2'} size="md" className="mb-4">
                    {title}
                </Heading>
                {description && <Markdown content={description} />}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <form className={passedStyles.form} onSubmit={handleSubmit(onSubmitLocal)}>
                    <div className="mb-12 w-full grid gap-6 tablet:gap-12">
                        <div className="grid gap-8 tablet:grid-cols-2">
                            <Controller
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        id="name"
                                        type="text"
                                        error={errors['name']}
                                        label={trans('fullName')}
                                    />
                                )}
                                rules={{ required: requiredText }}
                                control={control}
                                name="name"
                            />
                            <Controller
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        id="email"
                                        type="text"
                                        error={errors['email']}
                                        label={trans('email')}
                                    />
                                )}
                                rules={{ required: requiredText }}
                                control={control}
                                name="email"
                            />
                            <Controller
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        id="company"
                                        type="text"
                                        error={errors['company']}
                                        label={trans('company')}
                                    />
                                )}
                                rules={{ required: requiredText }}
                                control={control}
                                name="company"
                            />
                            <Controller
                                control={control}
                                name="phoneNumber"
                                rules={{
                                    required: requiredText,
                                    validate: (value) => isValidPhoneNumber(value),
                                }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        id="phoneNumber"
                                        label={trans('phoneNumber')}
                                        error={errors['phoneNumber']}
                                    />
                                )}
                            />
                        </div>
                        {children}
                    </div>

                    {gdpr}
                    <div className="mt-12 flex flex-col items-center">
                        {success && (
                            <Paragraph className="text-success mb-4" defaultStyles={false}>
                                {trans('form_success')}
                            </Paragraph>
                        )}
                        {globalError && (
                            <Paragraph className="text-alert mb-4" defaultStyles={false}>
                                {trans('form_error')}
                            </Paragraph>
                        )}
                        {loading ? <Loader /> : <Button type="submit">{buttonLabel}</Button>}
                    </div>
                </form>
            </div>
        </div>
    );
};
