export const CALENDAR_FORMATS: Record<string, unknown> = {
    cs: {
        lastDay: '[Včera]',
        sameDay: '[Dnes]',
        nextDay: '[Zítra]',
        lastWeek: 'l',
        nextWeek: 'l',
        sameElse: 'l',
    },
    en: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'L',
        nextWeek: 'dddd',
        sameElse: 'L',
    },
};

export const GOOGLE_MAP_KEY = 'AIzaSyAOil8knsSEZkMBwaZ8OcYefRTZ9EnJsQs';

export const NEWS_LIMIT = 6;
export const DATE_FORMAT = 'D. M. YYYY';
