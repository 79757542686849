/**
 * @generated SignedSource<<bb5dbca2d603d4756bd38e667137cf4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BundlesBlock_content$data = {
  readonly bundles: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badgeText: string | null;
        readonly currency: string | null;
        readonly description: string;
        readonly favorite: boolean | null;
        readonly position: number;
        readonly price: string | null;
        readonly pricePer: string | null;
        readonly screenings: string | null;
        readonly slug: string;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly bundlesDescription: string | null;
  readonly bundlesTitle: string | null;
  readonly id: string;
  readonly " $fragmentType": "BundlesBlock_content";
};
export type BundlesBlock_content$key = {
  readonly " $data"?: BundlesBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"BundlesBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BundlesBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "bundlesTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "bundlesDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleRelationResponseCollection",
      "kind": "LinkedField",
      "name": "bundles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BundleEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Bundle",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "badgeText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pricePer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "favorite",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "screenings",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "position",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentBundles",
  "abstractKey": null
};
})();

(node as any).hash = "e78d35b21dddee09805873bd1a1ff0ed";

export default node;
