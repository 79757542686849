import React from 'react';
import { companyFragment$data } from '../../../relay/__generated__/companyFragment.graphql';
import { Image } from '../../primitives/Image/Image';
import { OmitFragmentType } from '../../../headless';

export type CompanyProps = {
    company: OmitFragmentType<companyFragment$data>;
};

export const Company = ({ company }: CompanyProps) => {
    if (!company?.attributes?.logo.data?.attributes) {
        return null;
    }

    return (
        <div className="w-28 m-auto tablet:w-32 tablet-landscape:w-40 tablet-landscape:h-24">
            <Image image={company.attributes.logo.data.attributes} loading="eager" />
        </div>
    );
};
