import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { FaQsBlock_content$data } from './__generated__/FaQsBlock_content.graphql';
import { FAQs } from '../../components/blocks/FAQs/FAQs';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FAQsBlockStaticProps {}

export interface FAQsBlockContent extends OmitFragmentType<FaQsBlock_content$data> {
    __typename: 'ComponentContentFAQs';
}

export interface FAQsBlockProps extends FAQsBlockStaticProps {
    content: FAQsBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment FaQsBlock_content on ComponentContentFaQs {
        id
        faqsTitle: title
        faqsDescription: description
        faqs {
            data {
                ...faqFragment @relay(mask: false)
            }
        }
    }
`;

const FaQsBlock = ({ content, ...otherProps }: FAQsBlockProps): ReactElement => (
    <FAQs {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
FaQsBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<FAQsBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

FaQsBlock.whyDidYouRender = true;

export default FaQsBlock;
