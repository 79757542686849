import { ENUM_ICON_TITLE } from '../../relay/__generated__/iconFragment.graphql';
import { Icons } from '../../components/primitives/Icon/Icon';

export const convertIconEnumToIconNameMap: { [key in ENUM_ICON_TITLE]: Icons } = {
    Advers_media_V01_Green: 'advers-media-v01-green',
    Advers_media_V01_Orange: 'advers-media-v01-orange',
    Advers_media_V02_Green: 'advers-media-v02-green',
    Advers_media_V02_Orange: 'advers-media-v02-orange',
    Business_Green: 'business-green',
    Business_Orange: 'business-orange',
    Certification_Green: 'certification-green',
    Certification_Orange: 'certification-orange',
    Company_Green: 'company-green',
    Company_Orange: 'company-orange',
    Conflict_Green: 'conflict-green',
    Conflict_Orange: 'conflict-orange',
    Credibility_Green: 'credibility-green',
    Credibility_Orange: 'credibility-orange',
    Debtlessness_Green: 'debtlessness-green',
    Debtlessness_Orange: 'debtlessness-orange',
    Document_Green: 'document-green',
    Document_Orange: 'document-orange',
    Education_Green: 'education-green',
    Education_Orange: 'education-orange',
    Effective_process_Green: 'effective-process-green',
    Effective_process_Orange: 'effective-process-orange',
    Effective_process_V02_Green: 'effective-process-v02-green',
    Effective_process_V02_Orange: 'effective-process-v02-orange',
    Employees_Green: 'employees-green',
    Employees_Orange: 'employees-orange',
    GDPR_Green: 'gdpr-green',
    GDPR_Orange: 'gdpr-orange',
    History_Green: 'history-green',
    History_Orange: 'history-orange',
    Impunity_and_integrity_Green: 'impunity-and-integrity-green',
    Impunity_and_integrity_Orange: 'impunity-and-integrity-orange',
    Media_monitoring_Green: 'media-monitoring-green',
    Media_monitoring_Orange: 'media-monitoring-orange',
    Personal_documents_Green: 'personal-documents-green',
    Personal_documents_Orange: 'personal-documents-orange',
    Police_badge_Green: 'police-badge-green',
    Police_badge_Orange: 'police-badge-orange',
    Rating_Green: 'rating-green',
    Rating_Orange: 'rating-orange',
    Refere_people_Green: 'refere-people-green',
    Refere_people_Orange: 'refere-people-orange',
    Save_Green: 'save-green',
    Save_Orange: 'save-orange',
    Save_money_and_time_Green: 'save-money-and-time-green',
    Save_money_and_time_Orange: 'save-money-and-time-orange',
    Shield_Green: 'shield-green',
    Shield_Orange: 'shield-orange',
    Speed_Green: 'speed-green',
    Speed_Orange: 'speed-orange',
    Integrity: 'integrity',
    '%future added value': null,
};
