import React, { Fragment, ReactElement } from 'react';
import NextHead from 'next/head';
import { AppContextProps, OmitFragmentType } from '../../../headless';
import scaut from '../../../../scaut.config.json';
import { PageProps } from '../../../types/page';
import { WebSettingsProps } from '../../../types/webSettings';
import { seoSocialFragment$data } from '../../../relay/__generated__/seoSocialFragment.graphql';

export interface HeadProps {
    item?: AppContextProps<PageProps, WebSettingsProps>['item'];
    page?: NonNullable<AppContextProps<PageProps, WebSettingsProps>['pages']>['data'][number];
}

const renderSocialMeta = (data: OmitFragmentType<seoSocialFragment$data> | null) => {
    if (!data) {
        return;
    }
    const { description, title, image } = data;

    if (data.socialNetwork === 'Twitter') {
        return (
            <Fragment key={`${title}_${description}_twitter`}>
                {title && <meta name="twitter:title" content={title} />}
                {description && <meta name="twitter:description" content={description} />}
                {image?.data?.attributes?.url && (
                    <meta name="twitter:image" content={`${scaut.cms.domain}${image.data.attributes.url}`} />
                )}
                <meta name="twitter:card" content="summary_large_image" />
                {image?.data?.attributes?.alternativeText && (
                    <meta name="twitter:image:alt" content={image.data.attributes.alternativeText} />
                )}
            </Fragment>
        );
    }
    if (data.socialNetwork === 'Facebook') {
        return (
            <Fragment key={`${title}_${description}_facebook`}>
                {title && <meta property="og:title" content={title} />}
                {description && <meta property="og:description" content={description} />}
                {image?.data?.attributes?.url && (
                    <meta property="og:image" content={`${scaut.cms.domain}${image.data.attributes.url}`} />
                )}
            </Fragment>
        );
    }
};

export const Head = ({ item, page }: HeadProps): ReactElement => {
    const correctSource = item ? item : page;
    const seo = correctSource?.attributes?.seo;

    if (!seo) {
        return <></>;
    }

    const {
        metaTitle,
        metaDescription,
        metaImage,
        metaRobots,
        metaSocial,
        metaViewport,
        structuredData,
        keywords,
        canonicalURL,
    } = seo;

    return (
        <NextHead>
            {/* eslint-disable-next-line @next/next/no-sync-scripts */}
            <script
                id="cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="b04f1149-507b-4504-8816-c06b7f26bf36"
                data-blockingmode="auto"
                type="text/javascript"
            />
            {/* APP */}
            {metaTitle && <title>{metaTitle}</title>}
            {metaDescription && <meta name="description" content={metaDescription} />}
            {metaRobots && <meta name="robots" content={metaRobots} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {canonicalURL && <link rel="canonical" href={canonicalURL} />}
            {structuredData && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `${structuredData}`,
                    }}
                />
            )}
            {metaImage.data?.attributes?.url && (
                <meta property="og:image" content={`${scaut.cms.domain}${metaImage.data?.attributes?.url}`} />
            )}
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Scaut" />
            {metaSocial?.map(renderSocialMeta)}
            <meta charSet="UTF-8" />
            <meta
                name="viewport"
                content={
                    metaViewport
                        ? metaViewport
                        : 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover'
                }
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="default" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="msapplication-config" content="/browserconfig.xml" />

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=23452346345" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=23452346345" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=23452346345" />
            <link rel="manifest" href="/site.webmanifest?v=23452346345" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg?v=23452346345" color="#5bbad5" />
            <link rel="shortcut icon" href="/favicon.ico?v=23452346345" />
            <meta name="apple-mobile-web-app-title" content="Scaut" />
            <meta name="application-name" content="Scaut" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <script
                id="cookiebot-header"
                data-cookieconsent="ignore"
                dangerouslySetInnerHTML={{
                    __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500
      });
      gtag("set", "ads_data_redaction", true);`,
                }}
            />
            {/* GTM */}
            {scaut.gtm.code && (
                <script
                    data-cookieconsent="ignore"
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${scaut.gtm.code}');`,
                    }}
                />
            )}
        </NextHead>
    );
};
