import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { DocumentsBlock_content$data } from './__generated__/DocumentsBlock_content.graphql';
import { Documents } from '../../components/blocks/Documents/Documents';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { documentListQuery$data } from '../../relay/__generated__/documentListQuery.graphql';

export interface DocumentsBlockStaticProps {
    data: NonNullable<documentListQuery$data['items']>['data'];
}

export interface DocumentsBlockContent extends OmitFragmentType<DocumentsBlock_content$data> {
    __typename: 'ComponentContentDocuments';
}

export interface DocumentsBlockProps extends DocumentsBlockStaticProps {
    content: DocumentsBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment DocumentsBlock_content on ComponentContentDocuments {
        id
        title
        highlightedText
    }
`;

const DocumentsBlock = ({ content, ...otherProps }: DocumentsBlockProps): ReactElement => (
    <Documents {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    DocumentsBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<DocumentsBlockStaticProps> => {
        const provider = providers.document;

        const { data } = await provider.find({
            preview,
            locale,
            limit: 100,
            offset: 0,
        });

        return { data };
    };
}

DocumentsBlock.whyDidYouRender = true;

export default DocumentsBlock;
