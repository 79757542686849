/**
 * @generated SignedSource<<d0e853e804aa2ab95acfd8dba3e83c7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PricingBlock_content$data = {
  readonly id: string;
  readonly pricingDescription: string | null;
  readonly pricingTitle: string | null;
  readonly pricings: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly currency: string | null;
        readonly description: string | null;
        readonly price: string | null;
        readonly title: string;
        readonly unit: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "PricingBlock_content";
};
export type PricingBlock_content$key = {
  readonly " $data"?: PricingBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"PricingBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PricingBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "pricingTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "pricingDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricingRelationResponseCollection",
      "kind": "LinkedField",
      "name": "pricings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PricingEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Pricing",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentPricing",
  "abstractKey": null
};
})();

(node as any).hash = "3690e8ae182a4019949a157ef30ff07f";

export default node;
