import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { PromoFloorBlockContent, PromoFloorBlockProps } from '../../../blocks/PromoFloorBlock/PromoFloorBlock';
import styles from './PromoFloor.module.scss';
import { PromoCard } from '../../molecule/PromoCard/PromoCard';

export type PromoFloorProps = Omit<PromoFloorBlockProps, 'content'> & Omit<PromoFloorBlockContent, 'id' | '__typename'>;

export const PromoFloor = ({ className, promoItems }: PromoFloorProps): ReactElement => {
    return (
        <BlockWrapper className={clsx(styles.blockWrapper, className)}>
            <div className={'grid gap-4 tablet:grid-cols-3 desktop:gap-8'}>
                {promoItems?.data.map((item) => item.attributes && <PromoCard key={item.id} {...item.attributes} />)}
            </div>
        </BlockWrapper>
    );
};
