import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { ChecksBlockContent, ChecksBlockProps } from '../../../blocks/ChecksBlock/ChecksBlock';
import { Heading } from '../../primitives/Heading/Heading';
import styles from './Checks.module.scss';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Check } from '../../molecule/Check/Check';

export type ChecksProps = Omit<ChecksBlockProps, 'content'> & Omit<ChecksBlockContent, 'id' | '__typename'>;

export const Checks = ({ className, app, checks, checksDescription, checksTitle }: ChecksProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        <Heading tag={'h2'} className="mb-4" size="md">
            {checksTitle}
        </Heading>
        {checksDescription && <Markdown content={checksDescription} paragraphClass="text-center" />}
        <div className="mt-10 tablet:mt-20 grid gap-y-8 tablet:grid-cols-2 tablet:gap-x-8 tablet:gap-y-12 w-full">
            {checks.map(
                (check) =>
                    check.attributes && (
                        <Check
                            key={check.id}
                            {...check.attributes}
                            detailPage={app?.webSetting?.data?.attributes?.checkDetailPage?.data}
                        />
                    ),
            )}
        </div>
    </BlockWrapper>
);
