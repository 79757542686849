import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { ImageBlock_content$data } from './__generated__/ImageBlock_content.graphql';
import { Image } from '../../components/blocks/Image/Image';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImageBlockStaticProps {}

export interface ImageBlockContent extends OmitFragmentType<ImageBlock_content$data> {
    __typename: 'ComponentContentImage';
}

export interface ImageBlockProps extends ImageBlockStaticProps {
    content: ImageBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment ImageBlock_content on ComponentContentImage {
        id
        image: Image {
            data {
                ...appImageFragment @relay(mask: false)
            }
        }
        imageTitle: Title
    }
`;

const ImageBlock = ({ content, ...otherProps }: ImageBlockProps): ReactElement => (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
  ImageBlock.getStaticProps = async ({
      locale,
      providers,
  }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<ImageBlockStaticProps> => {
      const provider = providers.x;

      return {};
  };
  */
}

ImageBlock.whyDidYouRender = true;

export default ImageBlock;
