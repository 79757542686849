/**
 * @generated SignedSource<<204c860df39b39fc955d3e0406268a66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ENUM_COMPONENTCHECKDETAILCARDROW_STATE = "consideration" | "finding" | "ok" | "%future added value";
export type ENUM_COMPONENTCONTENTBUNDLEDETAIL_STATE = "consideration" | "finding" | "ok" | "%future added value";
export type ENUM_COMPONENTCONTENTCHECKDETAIL_STATE = "consideration" | "finding" | "ok" | "%future added value";
export type ENUM_COMPONENTCONTENTINFOTEXT_BACKGROUND_IMAGE_POSITION = "Bottom" | "Top" | "%future added value";
export type ENUM_ICON_TITLE = "Advers_media_V01_Green" | "Advers_media_V01_Orange" | "Advers_media_V02_Green" | "Advers_media_V02_Orange" | "Business_Green" | "Business_Orange" | "Certification_Green" | "Certification_Orange" | "Company_Green" | "Company_Orange" | "Conflict_Green" | "Conflict_Orange" | "Credibility_Green" | "Credibility_Orange" | "Debtlessness_Green" | "Debtlessness_Orange" | "Document_Green" | "Document_Orange" | "Education_Green" | "Education_Orange" | "Effective_process_Green" | "Effective_process_Orange" | "Effective_process_V02_Green" | "Effective_process_V02_Orange" | "Employees_Green" | "Employees_Orange" | "GDPR_Green" | "GDPR_Orange" | "History_Green" | "History_Orange" | "Impunity_and_integrity_Green" | "Impunity_and_integrity_Orange" | "Integrity" | "Media_monitoring_Green" | "Media_monitoring_Orange" | "Personal_documents_Green" | "Personal_documents_Orange" | "Police_badge_Green" | "Police_badge_Orange" | "Rating_Green" | "Rating_Orange" | "Refere_people_Green" | "Refere_people_Orange" | "Save_Green" | "Save_Orange" | "Save_money_and_time_Green" | "Save_money_and_time_Orange" | "Shield_Green" | "Shield_Orange" | "Speed_Green" | "Speed_Orange" | "%future added value";
export type ENUM_ROUTE_BUTTON_TYPE = "Filled" | "Outline" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type blocksContent$data = {
  readonly __typename: "ComponentContentBenefits";
  readonly benefits: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly description: string | null;
        readonly icon: {
          readonly data: {
            readonly attributes: {
              readonly title: ENUM_ICON_TITLE | null;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly position: number;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly benefitsDescription: string;
  readonly benefitsTitle: string;
  readonly highlightedText: string | null;
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentBundleDetail";
  readonly backToBundles: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly bundleState: ENUM_COMPONENTCONTENTBUNDLEDETAIL_STATE;
  readonly cardRow: ReadonlyArray<{
    readonly id: string;
    readonly showState: boolean | null;
    readonly state: ENUM_COMPONENTCHECKDETAILCARDROW_STATE;
    readonly title: string;
  } | null>;
  readonly downloadReport: string;
  readonly id: string;
  readonly routes: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentBundles";
  readonly bundles: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badgeText: string | null;
        readonly currency: string | null;
        readonly description: string;
        readonly favorite: boolean | null;
        readonly position: number;
        readonly price: string | null;
        readonly pricePer: string | null;
        readonly screenings: string | null;
        readonly slug: string;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly bundlesDescription: string | null;
  readonly bundlesTitle: string | null;
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentButton";
  readonly buttonDescription: string | null;
  readonly buttonTitle: string | null;
  readonly id: string;
  readonly route: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentCheckDetail";
  readonly backToChecks: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly cardRow: ReadonlyArray<{
    readonly id: string;
    readonly showState: boolean | null;
    readonly state: ENUM_COMPONENTCHECKDETAILCARDROW_STATE;
    readonly title: string;
  } | null>;
  readonly downloadReport: string;
  readonly id: string;
  readonly routes: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly state: ENUM_COMPONENTCONTENTCHECKDETAIL_STATE;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentChecks";
  readonly checksDescription: string | null;
  readonly checksTitle: string;
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentCompanies";
  readonly companiesDescription: string | null;
  readonly companiesTitle: string | null;
  readonly grayscale: boolean | null;
  readonly highlightedText: string | null;
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentContact";
  readonly address: string | null;
  readonly bankAccount: string | null;
  readonly contactTitle: string | null;
  readonly dataBox: string;
  readonly dic: string | null;
  readonly email: string | null;
  readonly highlightedText: string | null;
  readonly iban: string | null;
  readonly id: string;
  readonly mailingAddress: string;
  readonly phone: string | null;
  readonly subtitle: string | null;
  readonly vatNumber: string | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentDocuments";
  readonly highlightedText: string | null;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentError404";
  readonly description: string;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentFaQs";
  readonly faqs: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly description: string;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly faqsDescription: string | null;
  readonly faqsTitle: string | null;
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentHero";
  readonly heroDescription: string | null;
  readonly heroImage: {
    readonly data: {
      readonly attributes: {
        readonly alternativeText: string | null;
        readonly formats: any | null;
        readonly height: number | null;
        readonly name: string;
        readonly placeholder: string | null;
        readonly url: string;
        readonly width: number | null;
      } | null;
    } | null;
  } | null;
  readonly heroTitle: string;
  readonly highlightedText: string | null;
  readonly id: string;
  readonly routes: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentImage";
  readonly id: string;
  readonly image: {
    readonly data: {
      readonly attributes: {
        readonly alternativeText: string | null;
        readonly formats: any | null;
        readonly height: number | null;
        readonly name: string;
        readonly placeholder: string | null;
        readonly url: string;
        readonly width: number | null;
      } | null;
    } | null;
  };
  readonly imageTitle: string | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentInfotext";
  readonly animationFile: {
    readonly data: {
      readonly attributes: {
        readonly ext: string | null;
        readonly name: string;
        readonly size: number;
        readonly url: string;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly backgroundImagePosition: ENUM_COMPONENTCONTENTINFOTEXT_BACKGROUND_IMAGE_POSITION | null;
  readonly id: string;
  readonly infotextDescription: string;
  readonly infotextTitle: string;
  readonly infotextVideo: {
    readonly data: {
      readonly attributes: {
        readonly url: string;
        readonly videoHeight: number | null;
        readonly videoWidth: number | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly route: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly wideImage: boolean | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentMap";
  readonly highlightedText: string | null;
  readonly id: string;
  readonly mapDescription: string | null;
  readonly mapTitle: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentNewsDetail";
  readonly backToList: {
    readonly data: {
      readonly attributes: {
        readonly badge: string | null;
        readonly bundle: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
        readonly check: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly contactRequired: boolean | null;
        readonly file: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
            } | null;
          } | null;
        } | null;
        readonly newTab: boolean | null;
        readonly news: {
          readonly data: {
            readonly attributes: {
              readonly slug: string;
              readonly title: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly page: {
          readonly data: {
            readonly attributes: {
              readonly title: string;
              readonly url: string;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly params: string | null;
        readonly title: string;
        readonly url: string | null;
      } | null;
      readonly id: string | null;
    } | null;
  } | null;
  readonly id: string;
  readonly moreArticles: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentNewsList";
  readonly id: string;
  readonly loadMore: string;
  readonly moreArticles: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentNumbers";
  readonly highlightedText: string | null;
  readonly id: string;
  readonly numbers: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly number: string;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly numbersTitle: string | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentPricing";
  readonly id: string;
  readonly pricingDescription: string | null;
  readonly pricingTitle: string | null;
  readonly pricings: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly currency: string | null;
        readonly description: string | null;
        readonly price: string | null;
        readonly title: string;
        readonly unit: string | null;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentPromoFloor";
  readonly id: string;
  readonly promoItems: {
    readonly data: ReadonlyArray<{
      readonly attributes: {
        readonly description: string;
        readonly image: {
          readonly data: {
            readonly attributes: {
              readonly alternativeText: string | null;
              readonly formats: any | null;
              readonly height: number | null;
              readonly name: string;
              readonly placeholder: string | null;
              readonly url: string;
              readonly width: number | null;
            } | null;
          } | null;
        };
        readonly route: {
          readonly data: {
            readonly attributes: {
              readonly badge: string | null;
              readonly bundle: {
                readonly data: {
                  readonly attributes: {
                    readonly slug: string;
                    readonly title: string;
                  } | null;
                  readonly id: string | null;
                } | null;
              } | null;
              readonly buttonType: ENUM_ROUTE_BUTTON_TYPE | null;
              readonly check: {
                readonly data: {
                  readonly attributes: {
                    readonly slug: string;
                    readonly title: string;
                  } | null;
                  readonly id: string | null;
                } | null;
              } | null;
              readonly contactRequired: boolean | null;
              readonly file: {
                readonly data: {
                  readonly attributes: {
                    readonly url: string;
                  } | null;
                } | null;
              } | null;
              readonly newTab: boolean | null;
              readonly news: {
                readonly data: {
                  readonly attributes: {
                    readonly slug: string;
                    readonly title: string;
                  } | null;
                  readonly id: string | null;
                } | null;
              } | null;
              readonly page: {
                readonly data: {
                  readonly attributes: {
                    readonly title: string;
                    readonly url: string;
                  } | null;
                  readonly id: string | null;
                } | null;
              } | null;
              readonly params: string | null;
              readonly title: string;
              readonly url: string | null;
            } | null;
            readonly id: string | null;
          } | null;
        } | null;
        readonly title: string;
      } | null;
      readonly id: string | null;
    }>;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentQuote";
  readonly id: string;
  readonly quote: {
    readonly data: {
      readonly attributes: {
        readonly author: {
          readonly data: {
            readonly attributes: {
              readonly image: {
                readonly data: {
                  readonly attributes: {
                    readonly alternativeText: string | null;
                    readonly formats: any | null;
                    readonly height: number | null;
                    readonly name: string;
                    readonly placeholder: string | null;
                    readonly url: string;
                    readonly width: number | null;
                  } | null;
                } | null;
              };
              readonly occupation: string | null;
              readonly title: string;
            } | null;
          } | null;
        } | null;
        readonly content: string;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentReferences";
  readonly id: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentRichtext";
  readonly id: string;
  readonly text: string;
  readonly " $fragmentType": "blocksContent";
} | {
  readonly __typename: "ComponentContentVideo";
  readonly id: string;
  readonly video: {
    readonly data: {
      readonly attributes: {
        readonly url: string;
        readonly videoHeight: number | null;
        readonly videoWidth: number | null;
      } | null;
      readonly id: string | null;
    } | null;
  };
  readonly videoTitle: string | null;
  readonly " $fragmentType": "blocksContent";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "blocksContent";
};
export type blocksContent$key = {
  readonly " $data"?: blocksContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"blocksContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highlightedText",
  "storageKey": null
},
v2 = {
  "alias": "title",
  "args": null,
  "kind": "ScalarField",
  "name": "Title",
  "storageKey": null
},
v3 = {
  "alias": "description",
  "args": null,
  "kind": "ScalarField",
  "name": "Description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": "url",
  "args": null,
  "kind": "ScalarField",
  "name": "URL",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v8/*: any*/)
],
v10 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Route",
    "kind": "LinkedField",
    "name": "attributes",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": "badge",
        "args": null,
        "kind": "ScalarField",
        "name": "Badge",
        "storageKey": null
      },
      {
        "alias": "buttonType",
        "args": null,
        "kind": "ScalarField",
        "name": "Button_type",
        "storageKey": null
      },
      {
        "alias": "file",
        "args": null,
        "concreteType": "UploadFileEntityResponse",
        "kind": "LinkedField",
        "name": "File",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadFileEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadFile",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "newTab",
        "args": null,
        "kind": "ScalarField",
        "name": "New_tab",
        "storageKey": null
      },
      {
        "alias": "news",
        "args": null,
        "concreteType": "NewsEntityResponse",
        "kind": "LinkedField",
        "name": "News",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "News",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "slug",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "Slug",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "page",
        "args": null,
        "concreteType": "PageEntityResponse",
        "kind": "LinkedField",
        "name": "Page",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckEntityResponse",
        "kind": "LinkedField",
        "name": "check",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Check",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BundleEntityResponse",
        "kind": "LinkedField",
        "name": "bundle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Bundle",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "params",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contactRequired",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RouteEntity",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": (v10/*: any*/),
    "storageKey": null
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RouteEntity",
    "kind": "LinkedField",
    "name": "data",
    "plural": true,
    "selections": (v10/*: any*/),
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "RouteRelationResponseCollection",
  "kind": "LinkedField",
  "name": "routes",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadReport",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ComponentCheckDetailCardRow",
  "kind": "LinkedField",
  "name": "cardRow",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showState",
      "storageKey": null
    },
    (v15/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "RouteEntityResponse",
  "kind": "LinkedField",
  "name": "route",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v22 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UploadFileEntity",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UploadFile",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placeholder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "alternativeText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formats",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v23 = {
  "alias": "image",
  "args": null,
  "concreteType": "UploadFileEntityResponse",
  "kind": "LinkedField",
  "name": "Image",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UploadFileEntity",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UploadFile",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": false,
        "selections": [
          {
            "alias": "videoWidth",
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": "videoHeight",
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blocksContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "benefitsTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "Title",
          "storageKey": null
        },
        {
          "alias": "benefitsDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "Description",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": "benefits",
          "args": null,
          "concreteType": "BenefitRelationResponseCollection",
          "kind": "LinkedField",
          "name": "Benefits",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BenefitEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Benefit",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IconEntityResponse",
                      "kind": "LinkedField",
                      "name": "icon",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "IconEntity",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Icon",
                              "kind": "LinkedField",
                              "name": "attributes",
                              "plural": false,
                              "selections": [
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentBenefits",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "RouteEntityResponse",
          "kind": "LinkedField",
          "name": "backToBundles",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": "bundleState",
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        (v16/*: any*/)
      ],
      "type": "ComponentContentBundleDetail",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "bundlesTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "bundlesDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BundleRelationResponseCollection",
          "kind": "LinkedField",
          "name": "bundles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BundleEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Bundle",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v17/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "badgeText",
                      "storageKey": null
                    },
                    (v18/*: any*/),
                    (v19/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pricePer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "favorite",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "screenings",
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentBundles",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "buttonTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "buttonDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v20/*: any*/)
      ],
      "type": "ComponentContentButton",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "checksTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "checksDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "type": "ComponentContentChecks",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "backToChecks",
          "args": null,
          "concreteType": "RouteEntityResponse",
          "kind": "LinkedField",
          "name": "back",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        },
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ],
      "type": "ComponentContentCheckDetail",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "companiesTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": "companiesDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grayscale",
          "storageKey": null
        }
      ],
      "type": "ComponentContentCompanies",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "contactTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bankAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iban",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mailingAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataBox",
          "storageKey": null
        }
      ],
      "type": "ComponentContentContact",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        (v1/*: any*/)
      ],
      "type": "ComponentContentDocuments",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "ComponentContentError404",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "faqsTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "faqsDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FaqRelationResponseCollection",
          "kind": "LinkedField",
          "name": "faqs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FaqEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Faq",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentFaQs",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "heroTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "Title",
          "storageKey": null
        },
        {
          "alias": "heroDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "Description",
          "storageKey": null
        },
        {
          "alias": "highlightedText",
          "args": null,
          "kind": "ScalarField",
          "name": "Highlighted_text",
          "storageKey": null
        },
        {
          "alias": "routes",
          "args": null,
          "concreteType": "RouteRelationResponseCollection",
          "kind": "LinkedField",
          "name": "Routes",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": "heroImage",
          "args": null,
          "concreteType": "UploadFileEntityResponse",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v22/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ComponentContentHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v23/*: any*/),
        {
          "alias": "imageTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "Title",
          "storageKey": null
        }
      ],
      "type": "ComponentContentImage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "infotextTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "Title",
          "storageKey": null
        },
        {
          "alias": "infotextDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "Description",
          "storageKey": null
        },
        {
          "alias": "backgroundImagePosition",
          "args": null,
          "kind": "ScalarField",
          "name": "Background_image_position",
          "storageKey": null
        },
        {
          "alias": "wideImage",
          "args": null,
          "kind": "ScalarField",
          "name": "Wide_image",
          "storageKey": null
        },
        (v20/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadFileEntityResponse",
          "kind": "LinkedField",
          "name": "animationFile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UploadFileEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UploadFile",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    (v21/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ext",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "size",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "infotextVideo",
          "args": null,
          "concreteType": "UploadFileEntityResponse",
          "kind": "LinkedField",
          "name": "video",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ComponentContentInfotext",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "moreArticles",
          "args": null,
          "kind": "ScalarField",
          "name": "More_articles",
          "storageKey": null
        },
        {
          "alias": "backToList",
          "args": null,
          "concreteType": "RouteEntityResponse",
          "kind": "LinkedField",
          "name": "Back_to_list",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ComponentContentNewsDetail",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "mapTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "mapDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "type": "ComponentContentMap",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "loadMore",
          "args": null,
          "kind": "ScalarField",
          "name": "Load_more",
          "storageKey": null
        },
        {
          "alias": "moreArticles",
          "args": null,
          "kind": "ScalarField",
          "name": "more_articles",
          "storageKey": null
        }
      ],
      "type": "ComponentContentNewsList",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "numbersTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NumberRelationResponseCollection",
          "kind": "LinkedField",
          "name": "numbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NumberEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Number",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentNumbers",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "pricingTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "pricingDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricingRelationResponseCollection",
          "kind": "LinkedField",
          "name": "pricings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PricingEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pricing",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v17/*: any*/),
                    (v19/*: any*/),
                    (v18/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unit",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentPricing",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "promoItems",
          "args": null,
          "concreteType": "PromoItemRelationResponseCollection",
          "kind": "LinkedField",
          "name": "promo_items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PromoItemEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PromoItem",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v17/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UploadFileEntityResponse",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": (v22/*: any*/),
                      "storageKey": null
                    },
                    (v20/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentPromoFloor",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "quote",
          "args": null,
          "concreteType": "QuoteEntityResponse",
          "kind": "LinkedField",
          "name": "Quote",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuoteEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Quote",
                  "kind": "LinkedField",
                  "name": "attributes",
                  "plural": false,
                  "selections": [
                    {
                      "alias": "author",
                      "args": null,
                      "concreteType": "PersonEntityResponse",
                      "kind": "LinkedField",
                      "name": "Author",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PersonEntity",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "attributes",
                              "plural": false,
                              "selections": [
                                (v23/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": "occupation",
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "Occupation",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "content",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ComponentContentQuote",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "ComponentContentReferences",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "text",
          "args": null,
          "kind": "ScalarField",
          "name": "Text",
          "storageKey": null
        }
      ],
      "type": "ComponentContentRichtext",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "videoTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "Title",
          "storageKey": null
        },
        {
          "alias": "video",
          "args": null,
          "concreteType": "UploadFileEntityResponse",
          "kind": "LinkedField",
          "name": "Video",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ComponentContentVideo",
      "abstractKey": null
    }
  ],
  "type": "PageContentDynamicZone",
  "abstractKey": "__isPageContentDynamicZone"
};
})();

(node as any).hash = "5758d8ceb910cbbfbaf7c05e4d9e8c7b";

export default node;
