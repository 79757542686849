import React from 'react';
import clsx from 'clsx';

export interface BadgeProps {
    text: string;
    className?: string;
    withDefaultBackground?: boolean;
}

const Badge = ({ text, className, withDefaultBackground = true }: BadgeProps): JSX.Element => (
    <div className={clsx('rounded-lg px-4 py-2 inline-flex', withDefaultBackground && 'bg-primary', className)}>
        <p className="text-xs text-white uppercase">{text}</p>
    </div>
);

Badge.whyDidYouRender = true;

export { Badge };
