/**
 * @generated SignedSource<<bb1138165ea87643a44ea7f56cd3c096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MapBlock_content$data = {
  readonly highlightedText: string | null;
  readonly id: string;
  readonly mapDescription: string | null;
  readonly mapTitle: string;
  readonly " $fragmentType": "MapBlock_content";
};
export type MapBlock_content$key = {
  readonly " $data"?: MapBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"MapBlock_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MapBlock_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "mapTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": "mapDescription",
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightedText",
      "storageKey": null
    }
  ],
  "type": "ComponentContentMap",
  "abstractKey": null
};

(node as any).hash = "b02c49458efd997492b91bef15195d65";

export default node;
