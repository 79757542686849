import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { ContactBlock_content$data } from './__generated__/ContactBlock_content.graphql';
import { Contact } from '../../components/blocks/Contact/Contact';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContactBlockStaticProps {}

export interface ContactBlockContent extends OmitFragmentType<ContactBlock_content$data> {
    __typename: 'ComponentContentContact';
}

export interface ContactBlockProps extends ContactBlockStaticProps {
    content: ContactBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment ContactBlock_content on ComponentContentContact {
        id
        highlightedText
        contactTitle: title
        address
        bankAccount
        dic
        email
        iban
        subtitle
        vatNumber
        phone
        mailingAddress
        dataBox
    }
`;

const ContactBlock = ({ content, ...otherProps }: ContactBlockProps): ReactElement => (
    <Contact {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
ContactBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<ContactBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

ContactBlock.whyDidYouRender = true;

export default ContactBlock;
