import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType, StaticBlockContext } from '../../headless';
import { CompaniesBlock_content$data } from './__generated__/CompaniesBlock_content.graphql';
import { Companies } from '../../components/blocks/Companies/Companies';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';
import { Providers } from '../../types/providers';
import { Locale } from '../../types/locale';
import { companyListQuery$data } from '../../relay/__generated__/companyListQuery.graphql';

export interface CompaniesBlockStaticProps {
    companies: companyListQuery$data['items'];
}

export interface CompaniesBlockContent extends OmitFragmentType<CompaniesBlock_content$data> {
    __typename: 'ComponentContentCompanies';
}

export interface CompaniesBlockProps extends CompaniesBlockStaticProps {
    content: CompaniesBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment CompaniesBlock_content on ComponentContentCompanies {
        id
        companiesTitle: title
        highlightedText
        companiesDescription: description
        grayscale
    }
`;

const CompaniesBlock = ({ content, ...otherProps }: CompaniesBlockProps): ReactElement => (
    <Companies {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    CompaniesBlock.getStaticProps = async ({
        locale,
        providers,
        context: { preview },
    }: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<CompaniesBlockStaticProps> => {
        const provider = providers.company;

        const companies = await provider.find({
            offset: 0,
            limit: 100,
            locale,
            preview,
        });

        return {
            companies,
        };
    };
}

CompaniesBlock.whyDidYouRender = true;

export default CompaniesBlock;
