import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BlockWrapper } from '../../base/BlockWrapper/BlockWrapper';
import { DocumentsBlockContent, DocumentsBlockProps } from '../../../blocks/DocumentsBlock/DocumentsBlock';
import styles from './Documents.module.scss';
import { ColoredHeading } from '../../primitives/ColoredHeading/ColoredHeading';
import { Document } from '../../molecule/Document/Document';

export type DocumentsProps = Omit<DocumentsBlockProps, 'content'> & Omit<DocumentsBlockContent, 'id' | '__typename'>;

export const Documents = ({ className, title, data, highlightedText }: DocumentsProps): ReactElement => (
    <BlockWrapper className={clsx(styles.blockWrapper, className)}>
        <ColoredHeading text={title} highlightedText={highlightedText} className="mb-10 text-center tablet:mb-20" />
        <div className="w-full">
            {data.map((document) => document.attributes && <Document key={document.id} document={document} />)}
        </div>
    </BlockWrapper>
);
