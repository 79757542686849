import React, { ReactElement } from 'react';
import { graphql } from 'react-relay';
import { AppContextProps, OmitFragmentType } from '../../headless';
import { NumbersBlock_content$data } from './__generated__/NumbersBlock_content.graphql';
import { Numbers } from '../../components/blocks/Numbers/Numbers';
import { PageProps } from '../../types/page';
import { WebSettingsProps } from '../../types/webSettings';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NumbersBlockStaticProps {}

export interface NumbersBlockContent extends OmitFragmentType<NumbersBlock_content$data> {
    __typename: 'ComponentContentNumbers';
}

export interface NumbersBlockProps extends NumbersBlockStaticProps {
    content: NumbersBlockContent;
    app?: AppContextProps<PageProps, WebSettingsProps>;
    className?: string;
}

graphql`
    fragment NumbersBlock_content on ComponentContentNumbers {
        id
        numbersTitle: title
        highlightedText
        numbers {
            data {
                ...numberFragment @relay(mask: false)
            }
        }
    }
`;

const NumbersBlock = ({ content, ...otherProps }: NumbersBlockProps): ReactElement => (
    <Numbers {...{ ...content, id: undefined, __typename: undefined }} {...otherProps} />
);

if (typeof window === 'undefined') {
    // put your getStaticProps or getStaticPaths here
    /*
NumbersBlock.getStaticProps = async ({
locale,
providers,
}: StaticBlockContext<PageProps, WebSettingsProps, Providers, Locale>): Promise<NumbersBlockStaticProps> => {
const provider = providers.x;

return {};
};
*/
}

NumbersBlock.whyDidYouRender = true;

export default NumbersBlock;
