import React, { ReactElement } from 'react';
import { CheckDetailBlockContent, CheckDetailBlockProps } from '../../../blocks/CheckDetailBlock/CheckDetailBlock';
import { CommonDetailHero } from '../../organisms/CommonDetailHero/CommonDetailHero';

export type CheckDetailProps = Omit<CheckDetailBlockProps, 'content'> &
    Omit<CheckDetailBlockContent, 'id' | '__typename'>;

export const CheckDetail = ({
    item,
    backToChecks,
    routes,
    app,
    cardRow,
    downloadReport,
    state,
}: CheckDetailProps): ReactElement => {
    if (!item?.attributes || !app) {
        return <></>;
    }

    return (
        <CommonDetailHero
            badgeText={null}
            favorite={null}
            screenings={null}
            {...item.attributes}
            routes={routes?.data}
            back={backToChecks?.data}
            app={app}
            downloadReport={downloadReport}
            defaultState={state}
            defaultCardRow={cardRow}
        />
    );
};
