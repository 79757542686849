import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

export interface BlockWrapperProps {
    children?: ReactNode;
    className?: string;
    id?: string;
}

export const BlockWrapper = forwardRef(
    ({ children, className, id }: BlockWrapperProps, ref: ForwardedRef<HTMLDivElement>): ReactElement | null => {
        if (children || (Array.isArray(children) && children.length > 0)) {
            return (
                <section ref={ref} id={id} className={clsx('flex justify-center items-center flex-col', className)}>
                    {children}
                </section>
            );
        }
        return null;
    },
);

BlockWrapper.displayName = 'BlockWrapper';
