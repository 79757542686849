/**
 * @generated SignedSource<<b987045df6640409f09492dabae9df33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoBlock_content$data = {
  readonly id: string;
  readonly video: {
    readonly data: {
      readonly attributes: {
        readonly url: string;
        readonly videoHeight: number | null;
        readonly videoWidth: number | null;
      } | null;
      readonly id: string | null;
    } | null;
  };
  readonly videoTitle: string | null;
  readonly " $fragmentType": "VideoBlock_content";
};
export type VideoBlock_content$key = {
  readonly " $data"?: VideoBlock_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoBlock_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoBlock_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "videoTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "Title",
      "storageKey": null
    },
    {
      "alias": "video",
      "args": null,
      "concreteType": "UploadFileEntityResponse",
      "kind": "LinkedField",
      "name": "Video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadFileEntity",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UploadFile",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": "videoWidth",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": "videoHeight",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ComponentContentVideo",
  "abstractKey": null
};
})();

(node as any).hash = "3f08e87488b8e8ae2a79810941afdf9d";

export default node;
